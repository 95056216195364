import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { RequestService } from "@app/core/services/request.service";
import { S1Modal } from "@app/s1";

@Component({
  selector: "app-single-request",
  templateUrl: "./request.component.html",
  styleUrls: ["./request.component.scss"],
})
export class RequestComponent implements OnInit {
  @Input() request: any;
  @Input() showButtons: boolean = true;
  @Input() showNames: boolean = true;
  @ViewChild("approveReject") approveReject: S1Modal;
  @Output() changes = new EventEmitter();
  isApprove: boolean;

  constructor() {}

  ngOnInit(): void {}

  openModal(isApprove: boolean) {
    this.isApprove = isApprove;
    this.approveReject.open();
  }

  closeModal(condition: string) {
    this.approveReject.close();
  }

  sendChanges(){
    this.changes.emit();
  }

}
