import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import swal, { SweetAlertResult } from 'sweetalert2';

export enum S1PopUpType {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Info = "info",
  Question = "question"
}

@Injectable({
  providedIn: 'root'
})
export class S1UIService {

  constructor(private translate: TranslateService, private toasterService: ToastrService) { 
    
  }

  // Spinner
  showSpinner() {

    swal.fire({
      title: this.translate.instant('s1.swal.loading'),
      didOpen: () => {
          swal.showLoading();
      }
    });

  }

  closeSpinner() {
    swal.close();
  }

  // Popup
  async showPopup(type: S1PopUpType, title?: string, text?: string) {
    const popupTitle = await this.translate.get(title).toPromise();
    const popupText = await this.translate.get(text).toPromise();
    swal.fire(this.translate.instant(popupTitle), this.translate.instant(popupText), type);
  }

  showPopupNoPermission() {
    this.showPopup(S1PopUpType.Warning, 's1.swal.attention',  's1.swal.noPermission')
  }

  showHTTPErrorPopup(error: any) {
    
    let errorTitle = 's1.error'
    let errorMessage = 'genericError'

    if (error.statusText) {
      errorMessage = error.statusText;
    }

    if (error.status) {

      errorTitle = "" + error.status

      switch(error.status) {
        case 401: // Unauthorized
        case 403: // Forbidden
          errorMessage = this.translate.instant('APIErrors.' + error.status)
          break;
      }

    }

    this.showPopup(S1PopUpType.Error, errorTitle, errorMessage)

  }

  showDialogPopup(title: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        cancelButton: 'btn btn-xs btn-danger'
      }
    });

  }

  /*showSelectionPopup(title: string, elementList: any): Promise<any> {

    return swal({
      title: this.translate.instant(title),
      type: "question",
      input: 'radio',
      inputOptions: elementList
    });

  }

  showInputPopup(title: string, text: string, fieldText: string, initialValue: string = null): Promise<any> {

    return swal({
      title: this.translate.instant(title),
      text: text,
      input: 'number',
      inputValue: initialValue,
      inputPlaceholder: this.translate.instant(fieldText),
    });

  }*/

  // TOAST
  showSuccessToast(text: string) {
    this.toasterService.success(this.translate.instant(text), this.translate.instant("s1.toast.success"));
  }

}
