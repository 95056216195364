<ng-template #approveReject *ngIf="role==='ADMIN'">
  <div class="modal-header">
    <h4 class="modal-title">Richiesta di ferie</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body overflow-auto">
    <div class="card-body text-center">
      <p class="text-md mb-5">
        Sei sicuro di voler {{ isApprove ? "approvare" : "rifiutare" }} le ferie
        di
        <strong>
          {{ request.user.firstName }}
          {{ request.user.lastName }}
        </strong>
        dal
        <strong>{{
          request.startDate | date : "d MMMM yyyy" : "" : "it"
        }}</strong>
        al
        <strong>{{ request.endDate | date : "d MMMM yyyy" : "" : "it" }}</strong
        >?
      </p>
      <div class="d-flex flex-row justify-content-end">
        <app-my-button
          class="mr-2"
          text="RIFIUTA"
          bgColor="red-color"
          (click)="rejectRequest(request.id)"
          icon="fa fa-times fa-md"
        ></app-my-button>
        <app-my-button
          text="APPROVA"
          bgColor="green-color"
          (click)="approveRequest(request.id)"
          icon="fa fa-check fa-md"
        ></app-my-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #approveReject *ngIf="role!=='ADMIN'">
  <div class="modal-header">
    <h4 class="modal-title">Richiesta di ferie</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body overflow-auto">
    <div class="card-body text-center">
      <p class="text-md mb-5">
        Sei sicuro di voler eliminare la richiesta di  ferie
        dal
        <strong>{{
          request.startDate | date : "d MMMM yyyy" : "" : "it"
        }}</strong>
        al
        <strong>{{ request.endDate | date : "d MMMM yyyy" : "" : "it" }}</strong
        >?
      </p>
      <div class="d-flex flex-row justify-content-end">
        <app-my-button
          class="mr-2"
          text="ELIMINA"
          bgColor="red-color"
          (click)="rejectRequest(request.id)"
          icon="fa fa-times fa-md"
        ></app-my-button>
        <app-my-button
          text="ANNULLA"
          bgColor="blue-color"
          (click)="close()"
          icon="fa fa-check fa-md"
        ></app-my-button>
      </div>
    </div>
  </div>
</ng-template>
