import { AfterContentInit, Component, Input, OnInit } from "@angular/core";
import { User } from "@app/core/interfaces/admin.interface";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, AfterContentInit {
  @Input() isCollapsed: boolean = false;
  user: User;

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.user = JSON.parse(localStorage.getItem("userLogged"));
  }
}
