<div class="home-container">
    <div class="container">
      <div class="jumbotron-row col">
        <!-- Dynamic List Column -->
        <div class="jumbotron col-md-6 custom">
          <div class="d-flex flex-column h-100 px-5">

            <!-- Month / Year Selector Buttons -->
            <div class="d-flex row w-75 justify-content-between">
              <div class="btn-group" role="group" aria-label="Month / Year selector">
                <button type="button" class="btn btn-secondary" 
                  [ngClass]="{'bg-info' : monthYearSelector === 'MONTH'}"
                  [disabled] = "monthYearSelector === 'MONTH'"
                  (click) = "changeDateSelector()"
                  >MESE</button>
                <button type="button" class="btn btn-secondary"
                  [ngClass]="{'bg-info' : monthYearSelector === 'YEAR'}"
                  [disabled] = "monthYearSelector === 'YEAR'"
                  (click) = "changeDateSelector()"
                  >ANNO</button>
              </div>

              <!-- Month Year Sliders-->
              <ng-container *ngIf="monthYearSelector === 'YEAR' else monthSlider">
                  <div class="year-picker  text-center d-flex align-items-center justify-content-center">
                    <i class="icon-arrow-left" (click)="prevYear()"></i>
                    <span class="mx-2">{{ currYear }}</span>
                    <i class="icon-arrow-right" (click)="prevYear()"></i>
                  </div>
              </ng-container>

              <ng-template #monthSlider>
                <div class="year-picker text-center d-flex align-items-center justify-content-center">
                  <i class="icon-arrow-left" (click)="prevMonth()"></i>
                  <span class="mx-2">{{ currMonthYear }}</span>
                  <i class="icon-arrow-right" (click)="nextMonth()"></i>
                </div>
              </ng-template>
              
              
            </div>

            <app-dynamic-list
              class="flex-grow-1"
              [items]="dynamicListItems"
              #dynamicList
            ></app-dynamic-list>
          </div>
        </div>
        <!-- Calendar and Chart Column -->
        <div class="jumbotron-col col-md-6">
          <div class="d-flex flex-column">
            <!-- Chart Column (Smaller) -->
            <div class="jumbotron col-md-12 custom">
              <div class="chart-container">
                <app-column-chart
                  class="w-100 mb-3"
                  [items]="dynamicListItems"
                  [height]="250"
                ></app-column-chart>
              </div>
              <div class="calendar-container mt-2">
                <app-my-calendar
                  class="flex-grow-1"
                  (dateSelected)="onDateSelected($event)"
                  [height]="320"
                  [showSelectedCell]="true"
                ></app-my-calendar>
              </div>
            </div>
            <!-- Calendar Column (Bigger) -->
          </div>
        </div>
      </div>
  
  
  <!--     <s1-modal #modal [title]='selectedDate + " - " + statusKey'>
        <div class="container">
          <div class="row" *ngIf="statusKey !== 'ALTRO'">
            <s1-card class="w-100">
              <app-user-card [user]="listFiltered" (showModal)="onNavigation($event)"></app-user-card>
            </s1-card>
          </div>
          <div class="row" *ngIf="statusKey === 'ALTRO'">
            <s1-card class="w-100">
              <app-user-card [calendar]="otherList" (showModal)="onNavigation($event)"></app-user-card>
            </s1-card>
          </div>
        </div>
      </s1-modal> -->
      
    </div>
  
<!--     <s1-modal #modal [title]="selectedDate + ' - ' + statusKey">
      <app-user-card
        class="w-100"
        [user]="listFiltered"
        (showModal)="onNavigation($event)"
        *ngIf="statusKey !== 'ALTRO'"
      ></app-user-card>
      <app-user-card
        class="w-100"
        [calendar]="otherList"
        (showModal)="onNavigation($event)"
        *ngIf="statusKey === 'ALTRO'"
      ></app-user-card>
    </s1-modal> -->
  </div>
  