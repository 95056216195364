import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 's1-button-check',
  templateUrl: './s1-button-check.component.html',
  styleUrls: ['./s1-button-check.component.scss']
})
export class S1ButtonCheck implements OnInit {

  @Input() check = false;
  @Input() disabled = false;

  @Output() checkChange = new EventEmitter<boolean>();
  /** Output event to set a default value when check is used in a formGroup */
  @Output() createValue = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    this.createValue.emit(this.check);
  }

  toggle() {
    this.check = !this.check;
    this.checkChange.emit(this.check);
  }

}
