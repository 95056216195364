import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-weekdays',
  templateUrl: './weekdays.component.html',
  styleUrls: ['./weekdays.component.scss']
})
export class WeekdaysComponent {
  @Output() weekChanged: EventEmitter<any> = new EventEmitter<any>();

  currentDate: Date = new Date();
  startDay: string;
  startDayNumber: number;
  endDay: string;
  endDayNumber: number;
  endMonth: string;
  year: number;

  constructor() {

  }

  ngOnInit(){
    this.updateWeek()
  }updateWeek(): void {
    const startDate: Date = new Date(this.currentDate);
    startDate.setDate(startDate.getDate() - startDate.getDay() + (startDate.getDay() === 0 ? -6 : 1)); // Start from Monday
    this.startDay = startDate.toLocaleDateString('it-IT', { weekday: 'short' });
    this.startDayNumber = startDate.getDate();

    const endDate: Date = new Date(this.currentDate);
    endDate.setDate(endDate.getDate() - endDate.getDay() + (endDate.getDay() === 0 ? -6 : 5)); // End on Friday
    this.endDay = endDate.toLocaleDateString('it-IT', { weekday: 'short' });
    this.endDayNumber = endDate.getDate();
    this.endMonth = endDate.toLocaleDateString('it-IT', { month: 'short' });
    this.year = endDate.getFullYear();

    const weekString: string = `${this.startDay} ${this.startDayNumber}, ${this.endDay} ${this.endDayNumber} ${this.endMonth}, ${this.year}`;
    this.weekChanged.emit({
      start_day: this.startDay,
      start_day_number: this.startDayNumber.toFixed(0).padStart(2, '0'),
      start_month: (startDate.getMonth() + 1).toFixed(0).padStart(2, '0'),
      start_year: startDate.getFullYear(),
      end_day: this.endDay,
      end_day_number: this.endDayNumber.toFixed(0).padStart(2, '0'),
      end_month: (endDate.getMonth() + 1).toFixed(0).padStart(2, '0'),
      end_year: endDate.getFullYear()
    });
  }


  nextWeek(): void {
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.updateWeek();
  }

  prevWeek(): void {
    this.currentDate.setDate(this.currentDate.getDate() - 7);
    this.updateWeek();
  }
}
