<div class="rounded-4 rounded border border-black bg-white p-4">
  <h2 class="border-bottom border-black pt-3 pb-3">Richieste</h2>

  <!-- FIRST ROW -->
  <div class="container d-flex w-100 justify-content-between">
    <button type="button" class="btn btn-secondary w-25" (click)="filterByAll()"
      [ngClass]="{ 'border border-info text-dark': allRequests }">
      Tutte le Richieste
    </button>
    <button type="button" class="btn btn-secondary w-25" (click)="filterByVacation()"
      [ngClass]="{ 'border border-info text-dark': vacationRequests }">
      Richieste di Ferie
    </button>
    <button type="button" class="btn btn-secondary w-25" (click)="filterByClosing()"
      [ngClass]="{ 'border border-info text-dark': closingRequests }">
      Richieste di Chiusura
    </button>
  </div>

  <!-- SECOND ROW-->
  <div class="container d-flex justify-content-between mt-3">
    <div class="w-25 d-flex flex-column">
      <label for="userSelect" class="form-label">{{role==="ADMIN" ? 'Dipendente: ' : 'Chiusura Mensile: '}}</label>
      <s1-input-select id="userSelect" [itemsList]="usersList" (onSelect)="filterByUser($event)" *ngIf="role==='ADMIN'"></s1-input-select>
      <button class="btn btn-secondary" 
        *ngIf="role!=='ADMIN'"
        (click)="sendMonthlyRequest()">
        <i class="fa-regular fa-paper-plane fa-sm"></i>
         INVIA RICHIESTA</button>
    </div>
    <div class="w-25">
      <label for="statusSelect" class="form-label">Stato:</label>
      <s1-input-select id="statusSelect" [itemsList]="statusList" (onSelect)="filterByStatus($event)"></s1-input-select>
    </div>

    <div class="w-25 px-1" [ngClass]="{ ' border-bottom border-danger': !isDateValid }">
      <label htmlFor="monthPicker">Seleziona date:</label>

      <button (click)="showMonthPicker()" *ngIf="!isMonthPickerVisible" class="btn w-100 btn-outline-secondary">
        <i class="fa-regular fa-calendar"></i>
        {{ startDate && endDate ? startDate + " - " + endDate : "CALENDARIO" }}
      </button>

      <month-picker (monthRangeSelected)="handleMonthRangeSelected($event)" *ngIf="isMonthPickerVisible"
        (displayEvent)="changeMonthPickerVisibility($event)"></month-picker>
    </div>
  </div>

  <button class="btn btn-outline-info w-100 my-3" (click)="filterList()" [disabled]="!isDateValid">
    CERCA
  </button>

  <s1-table class="mt-5 w-10" [paginationVisible]="true" #table [onlinePagination]="false">
    <thead header>
      <tr class="row header p-0">
        <th class="col-2 d-flex justify-content-center">Tipologia</th>
        <th class="col-3 text-start">Dipendente</th>
        <th class="{{role==='ADMIN' ? 'col-2' : 'col-3'}}">Stato</th>
        <th class="{{role==='ADMIN' ? 'col-2' : 'col-2'}}">Date</th>
        <th class="col-1 d-flex justify-content-center">Visualizza</th>
        <th class="col-2 d-flex justify-content-center" *ngIf="role==='ADMIN'">Operazioni</th>
      </tr>
    </thead>
    <tbody body>
      <!--<app-admin-request *ngFor="let request of requestList" [request]="request" (modalEvent) = "handleModalEvent()"></app-admin-request>-->
      <app-admin-request *ngFor="let request of table.config.rows" [request]="request"
        (modalEvent)="handleModalEvent()"></app-admin-request>
    </tbody>
  </s1-table>
</div>