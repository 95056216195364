<div class="wrapper">
  <div class="block-center mt-4 wd-xl">
      <!-- START card-->
      <div class="card card-flat">
          <div class="card-header text-center bg-secondary">
              <a href="#">
                  <img class="block-center rounded" src="assets/img/logo-big.png" alt="Image" />
              </a>
          </div>
          <div class="card-body">
              <p class="text-center py-2">{{'activate.title' | translate}}</p>
              <form (ngSubmit)="onSubmit()" #formActivate="ngForm" class="form-validate" role="form" name="formActivate" novalidate="">
                  <p class="text-center">{{'activate.info' | translate}}</p>
                  <div class="form-group">
                    <label class="text-muted">{{'base.pwd' | translate}}</label>
                    <div class="input-group with-focus">
                        <input type="{{styleInput}}" class="form-control border-right-0" id="password" class="form-control" ngModel name="password" required password #password="ngModel"/>
                        <div class="input-group-append" (click)="clickType()">
                            <span class="input-group-text text-muted bg-transparent border-left-0">
                                <em class="fa fa-eye{{styleIcon}}"></em>
                            </span>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="errorMessage">{{errorMessage}}</div>
                  </div>
                  <button [disabled]="!formActivate.valid" class="btn btn-primary btn-block" type="submit">{{'activate.activation' | translate}}</button>
              </form>
          </div>
      </div>
      <!-- END card-->
      <div class="p-3 text-center">
          <span>&copy;</span>
          <span>{{ settings.getAppSetting('year') }}</span>
          <span class="mx-2">-</span>
          <span>{{ settings.getAppSetting('name') }}</span>
          <br/>
          <span>{{ settings.getAppSetting('description') }}</span>
      </div>
  </div>
</div>