import { AbstractControl } from "@angular/forms";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "s1-input-text",
  templateUrl: "./s1-input-text.component.html",
  styleUrls: ["./s1-input-text.component.scss"],
})
export class S1InputText implements OnInit {
  @Input() control: AbstractControl;
  // @Input() control: FormControl;
  @Input() name: string = ''
  @Input() value : string = ''
  @Input() label = "";
  @Input() hasAction = false;
  @Input() hasMargin = true;
  @Input() placeholder: string = "";
  @Input() hideLabel = false;
  @Input() readonly = false;
  @Input() type = "text";
  @Input() isValid = true;
  @Input() min = 0;
  @Input() max: number;
  @Input() showSearch = true;

  @Output() onEnter = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  getPlaceholder(): string {
    if (this.placeholder != null) {
      return this.placeholder;
    }
    return "";
  }

  checkIsValid(): boolean {
    return this.control?.valid || !this.control?.touched;
  }

  get ctrl() {
    return this.control as FormControl;
  }

  enterAction(): void {
    if (this.control.value && !this.control.errors) {
      this.onEnter.emit();
    }
  }
}
