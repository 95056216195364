<div class="home-container d-flex justify-content-center">
  <div class="jumbotron d-flex justify-content-center w-100">
    <div *ngIf="!selectedUser?.id" class="w-25">
      <s1-input-select
        [itemsList]="usersList"
        (onSelect)="showDashboard($event)"
        #select
        [placeholder]="'Seleziona un dipendente'"
      ></s1-input-select>
    </div>

    <section class="container page-container p-0 m-0" *ngIf="selectedUser?.id">
      <!-- First Row: Back Button -->
      <div class="row" *ngIf="role == 'ADMIN'">
        <div class="col-12">
          <span class="back-icon" (click)="goBackToGeneral()">
            <i class="fa-solid fa-chevron-left fa-lg cursor"></i> BACK
          </span>
        </div>
      </div>

      <!-- Second Row: Avatar, User Info, and Buttons -->
      <div class="row mt-3 w-100 justify-content-between ">
          <div class="col-3 d-flex justify-content-center align-items-center ">
            <app-avatar [name]="selectedUser.label" [size]="'100'"></app-avatar>
          </div>

        <div class="d-flex row justify-content-center col-6 ">
          <div class="mx-5">
            <label for="userName" class="mb-0">Nome: </label>
            <input
              type="text"
              name="userName"
              [value]="selectedUser.firstName"
              class="input-group-text m-0"
              [readOnly]="true"
            />

            <label for="todayDate" class="mb-0 mt-3">Oggi: </label>
            <input
              type="text"
              name="todayDate"
              class="input-group-text m-0 cursor"
              [ngClass]="
                getClassForCalendarStatusType(
                  todayCalendarevent?.calendarStatusType
                )
              "
              [readonly]="true"
              [value]="
                todayCalendarevent
                  ? todayCalendarevent.calendarStatusType
                  : 'INDECISO ✎'
              "
              (click)="
                openModalProva(this.todayDate, todayCalendarevent ?? null)
              "
            />
          </div>

          <div>
            <label for="userLastName" class="mb-0">Cognome: </label>
            <input
              type="text"
              name="userLastName"
              [value]="selectedUser.lastName"
              class="input-group-text m-0"
              [readOnly]="true"
            />

            <label for="tomorrowDate" class="mb-0 mt-3"
              >Domani ({{ tomorrowDate | date : "dd/MM" }}):
            </label>
            <input
              type="text"
              name="tomorrowDate"
              class="input-group-text m-0 cursor"
              [ngClass]="
                getClassForCalendarStatusType(
                  tomorrowCalendarevent?.calendarStatusType
                )
              "
              [readonly]="true"
              [value]="
                tomorrowCalendarevent
                  ? tomorrowCalendarevent.calendarStatusType
                  : 'INDECISO ✎'
              "
              (click)="
                openModalProva(this.tomorrowDate, tomorrowCalendarevent ?? null)
              "
            />
          </div>
        </div>

        <div class="d-flex flex-column align-items-end col-3">
          <div class="d-flex flex-column">
            <label for="" class="mb-0 label-vuota">Contatta</label>
            <app-my-button
              text="CONTATTA"
              (onClick)="openMailBox()"
              icon="fa fa-edit"
              class=""
            >
            </app-my-button>
          </div>
          <div class="mt-2 d-flex flex-column">
            <label for="" class="mb-0 label-vuota">Visualizza</label>
            <app-my-button
              text="VISUALIZZA"
              (onClick)="redirect()"
              icon="fa fa-eye"
              class=""
            >
            </app-my-button>
          </div>
        </div>
      </div>

      <!-- Third Row: Annual Summary, Calendar, and Monthly Summary -->
      <div class="row mt-5 d-flex justify-content-between">
        <div class="col-lg-7 col-md-8 col-12 ml-3">
          <!-- Calendar -->
          <div class="container calendar-container">
            <app-my-calendar
              (startMonthSelected)="handleCalendarMonth($event)"
              [events]="events"
              [height]="400"
              [selectDate]="false"
              [showModal]="true"
              [user] = "selectedUser"
              (onModalClose)="getCalendarevents()"
            ></app-my-calendar>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-12 mt-4">
          <!-- Monthly Summary -->
          <div
            class="w-100 px-1"
            [ngClass]="{ ' border-bottom border-danger': !isDateValid }"
          >
            <label htmlFor="monthPicker">Seleziona date:</label>

            <button
              (click)="showMonthPicker()"
              *ngIf="!isMonthPickerVisible"
              class="btn w-100 btn-outline-secondary"
            >
              <i class="fa-regular fa-calendar"></i>
              {{
                startDate && endDate
                  ? startDate + " - " + endDate
                  : "CALENDARIO"
              }}
            </button>

            <month-picker
              (monthRangeSelected)="onMonthSelected($event)"
              *ngIf="isMonthPickerVisible"
              (displayEvent)="changeMonthPickerVisibility($event)"
              (year)="onYearSelected($event)"
              [selectionMode]="'single'"
            ></month-picker>
          </div>

          <div class="mt-0">
            <app-dynamic-list [items]="dataList" [hover]="true" ></app-dynamic-list>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
