import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-lengend',
  templateUrl: './map-lengend.component.html',
  styleUrls: ['./map-lengend.component.scss']
})
export class MapLengendComponent implements OnInit {

  constructor() { }

  labels: string[] = ["UFFICIO", "FERIE", "TRASFERTA", "FESTIVITA", "MALATTIA", "REMOTE", "PERMESSO","ALTRO"]
  ngOnInit(): void {
  }


  toLower(string:string){
    return this.toLower(string)
  }

}
