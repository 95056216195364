<div class="jumbotron">
  <div class="container text-center justify-content-center">
    <h2>Aggiungi un giorno festivo</h2>
    <h4>Seleziona una data:</h4>
    <div class="w-100 d-flex justify-content-center">
      <div class="w-75 py-5">
        <app-my-calendar
          #calendar
          [height]="350"
          class="w-75"
          [events] = "events"
          (dateSelected)="onDateSelected($event)"
          [showSelectedCell]="true"
          #calendario
        ></app-my-calendar>
        <div class="mt-2">
          <span *ngIf="showError" class="text-danger"
            >Seleziona una data nel calendario!</span
          >
        </div>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center">
      <app-my-button
        text="Aggiungi"
        (onClick)="onConfirm()"
        icon="fa fa-plus"
        bgColor="green-color"
      >
      </app-my-button>
    </div>
  </div>
</div>

<s1-modal #modal>
  <div>
    <p class="text-md">
      Sei sicuro di voler creare un giorno festivo in data
      <strong> {{ date | date : "fullDate" : "" : "it" }}</strong> ?
    </p>
    <p>
      ATTENZIONE: l'azione sarà irreversibile, procedi solo dopo aver
      controllato che la data sia corretta.
    </p>
  </div>

  <div class="d-flex justify-content-end mt-5">
    <app-my-button
      text="CREA"
      (onClick)="createHoliday()"
      icon="fa fa-plus"
      bgColor="green-color"
    >
    </app-my-button>
  </div>
</s1-modal>
