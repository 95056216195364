import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { LoggingService } from "./log.service";
import { Observable, throwError } from "rxjs";
import { LoggedUserInfo } from "../interfaces/admin.interface";

@Injectable()
export class AuthService {
  private;
  apiUrl = environment.restBaseUrl;

  pathInfoVersion = "/info";
  loggedUserInfoLocalStorageKey = "loggedUserInfo";

  constructor(private http: HttpClient, private logger: LoggingService) {}

  login(loginData: Object): any {
    return this.http
      .post<any[]>(
        this.apiUrl + "/authenticate",
        loginData,
        this.getRequestOptionArgs()
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            localStorage.setItem("token", res.data.accessToken);
            return res;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            localStorage.clear();
            return res;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.logger.log("Service Fail. Error:", error, 200);
          return throwError(error);
        })
      );
  }

  logout(): any {
    localStorage.clear();

    return this.http
      .post<any[]>(
        this.apiUrl + "/logout",
        null,
        this.getRequestOptionArgs()
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("Logout", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("Logout", " FAILURE", 200);
            return res;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  /* forgotPwd(email: string): any {
    localStorage.clear();

    const mailObj: any = {
      email: email,
    };

    return this.http
      .put<any[]>(
        "https://testapi-gestionepresenze.soluzione1.it/forgot",
        mailObj,
        this.getRequestOptionArgs()
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("Forgot pwd", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("Forgot pwd", " FAILURE", 200);
            return res;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  } */

  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/forgot`, { email: email });
  }

  changePassword(password: string, otp: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/activate/password`, {
      pinOTP: otp,
      password: password,
    });
  }

  /* changePwd(passwords: Object): any {
    return this.http
      .put<any[]>(
        this.apiUrl + "/activate/password",
        passwords,
        this.getRequestOptionArgs()
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("Change PWD", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("Change PWD", " FAILURE", 200);
            return res;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  } */

  activate(data: Object): any {
    localStorage.clear();

    return this.http
      .put<any[]>(
        this.apiUrl + "/user/activate",
        data,
        this.getRequestOptionArgs()
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("Activate", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("Activate", " FAILURE", 200);
            return res;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  invite(idUser: number): any {
    let invite: any = {};

    return this.http
      .put<any[]>(
        this.apiUrl + "/admin/user/invite/" + idUser,
        invite,
        this.getRequestOptionArgs()
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("Forgot pwd", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("Forgot pwd", " FAILURE", 200);
            return res;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  // Rest Items Service: Read one element (detail)
  getVersion(path) {
    path = this.apiUrl + path;
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const res: any = response;
        this.logger.log("Infos:", res, 200);
        return res;
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        return throwError(errorResponse.error);
      })
    );
  }

  getRequestOptionArgs(): any {
    console.log("getRequestOptions");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return httpOptions;
  }

  getLoggedUserInfoFromLocalStorage(): LoggedUserInfo {
    const loggedUserInfo = localStorage.getItem(
      this.loggedUserInfoLocalStorageKey
    );
    if (loggedUserInfo) {
      return JSON.parse(loggedUserInfo);
    }
    return null;
  }
}
