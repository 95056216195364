import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { AppService } from "@app/core/services/base.service";
import { IS1InputSelectItem } from "@app/s1/components/s1-input-select/s1-input-select.component";
import { EventInput, EventSourceInput } from "@fullcalendar/core";
import { map } from "rxjs/operators";
import { RoleService } from "@app/core/services/role-service";
import { formatDate } from "@angular/common";
import { isObject } from "lodash";
import { DateRangePickerComponent } from "@app/newComponents/date-range-picker/date-range-picker.component";
import swal from 'sweetalert2';

@Component({
  selector: "app-vacation",
  templateUrl: "./vacation.component.html",
  styleUrls: ["./vacation.component.scss"],
})
export class VacationComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit{

  @ViewChild("datePicker") datePicker: DateRangePickerComponent
  vacationsApproved: EventSourceInput = [];
  requestApproved: any[] = [];
  requests = [];
  userId: string;
  usersList: IS1InputSelectItem[] = [];
  filteredUsersList: IS1InputSelectItem[] = [];

  statusSelected: string;
  name: string;
  startDate: string;
  endDate: string;

  user: any;
  form : FormGroup
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    private roleService : RoleService 
  ) {}

  async ngOnInit(): Promise<void> {

    const role = localStorage.getItem('role')
    this.roleService.setRole(role)

    await this.loadInitialData();
    this.filteredUsersList = this.usersList;
   
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    const calendar = document.getElementById("calendar");
    if (calendar) {
      calendar.remove();
    }
  }

  onChange($event) {
    this.loadInitialData();
    
  }

  get role(): string{
    return localStorage.getItem("role")
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["startDate"] &&
      changes["startDate"].currentValue &&
      changes["endDate"] &&
      changes["endDate"].currentValue && 
      changes['requests'] &&
      changes['requests'].currentValue

    ) {
      this.getVacationsFiltered();
      this.getRequest()      
    }
  }

  private async loadInitialData() {
      await this.getUsers(),
      await this.getRequest(),
      await this.getVacations()
  }


  private getRequest(): Promise<void> {

    this.requests = [];
    return this.roleService.getRequestsFiltered('FERIE', null, null, 'PENDING')
      .pipe(map((res) => res.results))
      .toPromise()
      .then((results) => {
        this.requests = [];

        results.forEach((request) => {
          this.requests.push(request);
        });
      });
  }
  
  selectedUser($event) {
    this.userId = $event.id;
    this.onSearch();
  }

  onSearch() {
    this.getVacationsFiltered();
  }

  private getVacations(): Promise<void> {
    return this.roleService.getRequestsFiltered('FERIE', this.startDate, this.endDate, null)
      .pipe(map((res) => res.results))
      .toPromise()
      .then((results) => {
        const events: EventInput[] = [];
        results.forEach((request) => {
          const event: EventInput = {
            id: request.id,
            title: request.user.firstName + " " + request.user.lastName,
            start: request.startDate,
            end: this.addOneDay(request.endDate),
            allDay: true,
          };
          events.push(event);
          this.requestApproved.push(request);
        });
        this.vacationsApproved = events;
      });
  }
  
  private addOneDay(dateStr: string): string {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  }
  
  private getVacationsFiltered(): Promise<void> {
    
    if (this.userId !== undefined) {
      return this.roleService.getRequestsFiltered('FERIE', this.startDate, this.endDate, 'APPROVED', this.userId)
        .pipe(map((res) => res.results))
        .toPromise()
        .then((results) => {
          const events: EventInput[] = [];
          results.forEach((request) => {
            const event: EventInput = {
              id: request.id,
              title: request.user.firstName + " " + request.user.lastName,
              start: request.startDate,
              end: request.endDate,
              allDay: true,
            };
            events.push(event);
            this.requestApproved.push(request);
          });
          this.vacationsApproved = events;
        });
    } else {
      this.getVacations();
    }
  }

  private getUsers(): Promise<void> {
    const options: IS1InputSelectItem[] = [];
    return this.appService
      .getAll(`/api/admin/users`)
      .pipe(map((res) => res.results))
      .toPromise()
      .then((usersFound) => {
        usersFound.forEach((el) => {
          const option: IS1InputSelectItem = {
            code: el.firstName,
            label: el.firstName + " " + el.lastName,
            id: el.id,
          };
          options.push(option);
        });
        this.usersList = options;
      });
  }

  setStartDate($event) {
    this.startDate = $event;
  }

  setEndDate($event) {
    this.endDate = $event;
    this.getVacationsFiltered();
  }
  initForm(){
    this.form = this.formBuilder.group({
      user :  new FormControl(`${this.user.firstName} ${this.user.lastName}`)
    })
  }


  isDateSelected: boolean = false;
  isDateValid: boolean = false;
  vacationStartDate: string = "";
  vacationEndDate: string = "";

  handleDateSelected(event: { start: Date, end: Date }) {
    this.isDateSelected = true
    this.isDateValid = event.start > new Date() && event.end > new Date() 
    console.log('Date selezionate:', event.start, event.end);
    this.vacationStartDate = formatDate(event.start, "yyyy-MM-dd", "it-IT") 
    this.vacationEndDate = formatDate(event.end, "yyyy-MM-dd", "it-IT") 

  }

  requestVacations(){
    console.log(this.vacationEndDate, this.vacationStartDate)

    const body = {
      startDate: this.vacationStartDate,
      endDate: this.vacationEndDate,
      requestType: "FERIE"
    }

    this.roleService.postRequest(body)
      .subscribe((response: any) => {
        console.log(response);
        response.outcome.success ? 
        (
          swal.fire({
            icon: isObject(response.data) ? 'success' : "error",
            title: isObject(response.data) ? 'SUCCESSO' : "ERRORE",
            text: isObject(response.data) ? 'Richiesta inviata con successo' : response.data,
          }),
          this.getVacations(),
          this.datePicker.clearDates(),
          this.isDateSelected = false,
          this.getRequest()
        ): null
      })
      
  }

}
