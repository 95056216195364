<button type="button" class="btn" (click)="ask()">
  <i *ngIf="!checkedAll && uncheckedAll" class="far fa-square fa-2x"></i>
  <i *ngIf="!checkedAll && !uncheckedAll" class="fa fa-minus-square text-warning fa-2x"></i>
  <i *ngIf="checkedAll && !uncheckedAll" class="fa fa-check-square text-success fa-2x"></i>
</button>

<s1-modal #modal [title]="title">
  <h4 class="m-2">
    {{ description | translate }}
  </h4>
  <s1-button [type]="s1ButtonType.Check" [label]="'s1.buttonCheckAll.checkAll'" (click)="check()"></s1-button>
  <s1-button class="ml-2" [type]="s1ButtonType.Uncheck" [label]="'s1.buttonCheckAll.uncheckAll'" (click)="uncheck()"></s1-button>
</s1-modal>
