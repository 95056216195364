import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, locale?: string): any {
    return super.transform(value, 'fullDate', '', locale);
  }
}