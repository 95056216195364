<tabset [justified]="true">
    <tab>
        <ng-template tabHeading>
            <i class="icon-drawer"></i>
            <p> Notifiche </p>
        </ng-template>
        <div (click)="goToFestivita()">
            <app-notification-card [notifications]="notifications" ></app-notification-card>
        </div>

    </tab>
    

</tabset>
