import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "@app/core/interfaces/admin.interface";
import { AuthService } from "@app/core/services/auth.service";
import { SettingsService } from "@app/core/settings/settings.service";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidators } from "ngx-custom-validators-2";
import swal from "sweetalert2";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  changePasswordForm: UntypedFormGroup;
  email: string;

  // string for errorMessage service
  msgTranslation;
  errorTranslation;
  errorMessage;
  logger: any;

  constructor(
    public settings: SettingsService,
    fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    // messaggi
    this.translate.get("error").subscribe((res) => {
      this.errorTranslation = res;
    });

    this.translate.get("msg").subscribe((res) => {
      this.msgTranslation = res;
    });

    // form
    const OTP = new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]{6}$"),
      ])
    );
    const password = new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9]{6,10}$"),
      ])
    );
    const confirmPassword = new UntypedFormControl("", [
      Validators.required,
      CustomValidators.equalTo(password),
    ]);

    this.changePasswordForm = fb.group({
      OTP,
      password,
      confirmPassword,
    });
  }
  ngOnInit() {
    this.email = localStorage.getItem("email");
  }

  ngOnDestroy() {
    localStorage.removeItem("email");
  }

  onSubmit(): void {
    const otp = this.changePasswordForm.value.OTP;
    const password = this.changePasswordForm.value.password;

    swal.fire({
      title: this.msgTranslation.loading,
      didOpen: () => {
        swal.showLoading();
      },
    });

    this.authService.changePassword(password, otp).subscribe(
      (response) => {
        if (response.outcome.success === true) {
          swal.fire("Ottimo!", "Password modificata con successo", "success");
          this.router.navigate(["/login"]);
        } else {
          swal.fire(
            this.msgTranslation.error,
            this.settings.manageErrorMsg(response.outcome),
            "error"
          );
        }
      },
      (error) => {
        swal.fire(
          this.msgTranslation.error,
          "Il codice otp inserito non è corretto",
          "error"
        );
      }
    );
  }
}
