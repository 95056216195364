<div class="wrapper">
    <div class="abs-center">
        <div class="text-center my-3">
            <h1 class="mb-3">
                <sup>
                    <em class="fa fa-cog fa-2x text-muted fa-spin text-info"></em>
                </sup>
                <em class="fa fa-cog fa-5x text-muted fa-spin text-purple"></em>
                <em class="fa fa-cog fa-lg text-muted fa-spin text-success"></em>
            </h1>
            <div class="text-bold text-lg mb-3">SITE IS UNDER MAINTENANCE</div>
            <p class="lead m0">We'll back online shortly!</p>
        </div>
    </div>
</div>
