<button
  class="btn shadow-sm d-flex align-items-center py-2 px-3 rounded border-{{
    bgColor
  }}
  bg-{{ bgColor }} {{
    icon != null ? 'justify-content-between' : 'justify-content-center'
  }}"
  (click)="click()"
  [disabled]="disabled"
>
  <em
    *ngIf="icon != null"
    class="mr-2 text-{{ elementsColor }} {{ icon }}"
  ></em>
  <p class="m-0 text-{{ elementsColor }}">{{ text }}</p>
</button>
