export class LoginData {
  public username: string;
  public password: string;

    constructor(user: string, pwd: string) {
        this.username = user;
        this.password = pwd;
    }
    greet() {
        return "Hello, ";
    }
}
