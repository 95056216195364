<!-- NORMAL VIEW -->
<div *ngIf="!isCollapsed" class="background-profile border-top d-flex align-items-center">
  <div class="d-flex flex-row align-items-center py-3 justify-content-start">
    <div class="">
      <app-avatar class="mx-2 mb-3 " [name]="'admin'" [size]="50"></app-avatar>
    </div>
    <div class="d-flex flex-column ">
      <strong>{{user.firstName}} {{ user.lastName}}</strong>
      <p>{{user.email}}</p>
    </div>
  </div>
</div>

<!-- SMALL VIEW -->
<div *ngIf="isCollapsed" class="d-flex flex-column align-items-center py-2">
  <app-avatar class="avatar" [name]="'admin'" [size]="40"></app-avatar>
</div>
