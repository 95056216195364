<section class="text-center">
  
  <s1-card [title]="config.title" [headerStyle]="headerStyle">
    <div header-right class="float-right">
      <s1-button [type]="s1ButtonType.Add" (onClick)="searchElement()"></s1-button>
    </div>
    <s1-table #table [onlinePagination]="false">
      <thead header>
        <tr>
          <th> # </th>               
          <th>{{ 's1.standardManageSection.table.columns.codice' | translate }}</th>
          <th>{{ 's1.standardManageSection.table.columns.descrizione' | translate }}</th>
          <th></th>                                      
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let element of table.config.rows; let i = index">
          <td>{{ i | s1TableGeneralIndex: table }}</td>
          <td>{{ element.codice }}</td>
          <td>{{ element.descrizione }}</td>
          <td class="text-right">
            <s1-button (onClick)="removeElement(element)" [type]="s1ButtonType.Remove"></s1-button>
          </td>
        </tr>
      </tbody>
    </s1-table>
  </s1-card>
</section>

<s1-standard-search-modal #standardSearchModal (elementSelected)="addElement($event)"></s1-standard-search-modal>
