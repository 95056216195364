<div class="wrapper">
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper"></app-header>

  <!-- sidebar-->
  <app-sidebar class="aside-container"></app-sidebar>

  <!-- offsidebar-->
  <app-offsidebar class="offsidebar offside-bar"></app-offsidebar>

  <!-- Content -->
  <section class="section-container">
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </section>

  <!-- Page footer-->
  <footer class="footer-container" app-footer></footer>
</div>
