import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { toUpper } from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { RoleService } from "@app/core/services/role-service";
import { CalendarEvent } from "@app/core/interfaces/admin.interface";
import swal from "sweetalert2";
import { error } from "console";

@Component({
  selector: "app-create-calendarevent-modal",
  templateUrl: "./create-calendarevent-modal.component.html",
  styleUrls: ["./create-calendarevent-modal.component.scss"],
})
export class CreateCalendareventModalComponent implements OnInit {
  @Output() onClose = new EventEmitter<void>();
  @Input() date: string;
  @Input() userId: string;
  @Input() calendarEvent: CalendarEvent;

  note: string;
  ore: number;
  selectedStatus: string;
  isNewEvent: boolean = true;

  constructor(private roleService: RoleService, public modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    if (this.calendarEvent) {
      this.isNewEvent = false;
      this.selectedStatus = this.calendarEvent.calendarStatusType;
      this.note = this.calendarEvent.note;
      this.ore = this.calendarEvent.ore;
    }
  }

  toggleSelectedStatus(status: string): void {
    if (this.selectedStatus === status) {
      this.selectedStatus = null;
    } else {
      this.selectedStatus = status;
    }
  }

  onSubmit(): void {
    const formData = this.prepareFormData();

    if (this.isNewEvent) {
      this.createCalendarEvent(formData);
    } else {
      this.updateCalendarEvent(formData);
    }
  }

  prepareFormData(): any {
    const formData: any = {
      calendarDay: this.date,
      calendarStatusType: toUpper(this.selectedStatus),
      note: this.note,
      ore: this.ore,
    };
    return formData;
  }

  createCalendarEvent(formData: any): void {
    this.roleService.postNewCalendar(formData, this.userId).subscribe((response) => {
    
        if(response.outcome.success){
          swal.fire({
            icon: 'success',
            title: "SUCCESSO",
            text: "Giornata creata con successo"
          })
          this.close();
        }
    }, (error)=> {
      swal.fire({
        icon: 'error',
        title: "ERRORE",
        text: error.data
      })
      this.close()
    });
  }

  updateCalendarEvent(formData: any): void {
    this.roleService.editCalendarData(this.calendarEvent.id, formData).subscribe((response) => {
      if(response.outcome.success){
        swal.fire({
          icon: 'success',
          title: "SUCCESSO",
          text: "Giornata modificata con successo"
        })
        this.close();
      }
  }, (error)=> {
    swal.fire({
      icon: 'error',
      title: "ERRORE",
      text: error.data
    })
    this.close()
  });
  }

  close(): void {
    this.modalRef.hide();
    this.onClose.emit();
  }
}
