import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { S1Module } from "../s1/s1.module";
import { DateRange, MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { RatingModule } from "ngx-bootstrap/rating";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { FlotDirective } from "./directives/flot/flot.directive";
import { SparklineDirective } from "./directives/sparkline/sparkline.directive";
import { ColorsService } from "./colors/colors.service";
import { CheckallDirective } from "./directives/checkall/checkall.directive";
import { VectormapDirective } from "./directives/vectormap/vectormap.directive";
import { NowDirective } from "./directives/now/now.directive";
import { ScrollableDirective } from "./directives/scrollable/scrollable.directive";
import { HighChartComponent } from "@app/newComponents/high-chart/high-chart.component";
import { CalendarComponent } from "@app/newComponents/calendar/calendar.component";
import { DynamicListComponent } from "@app/newComponents/dynamic-list/dynamic-list.component";
import { RequestComponent } from "@app/newComponents/request/request.component";
import { AvatarComponent } from "@app/newComponents/avatar/avatar.component";
import { UserCardComponent } from "@app/newComponents/user-card/user-card.component";
import { ModalUserComponent } from "@app/newComponents/modal-user/modal-user.component";
import { AdminRequestComponent } from "@app/newComponents/admin-request/admin-request.component";
import { WeekdaysComponent } from "@app/newComponents/weekdays/weekdays.component";
import { ProfileComponent } from "@app/newComponents/profile/profile.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { MonthPickerComponent } from "@app/newComponents/month-picker/month-picker.component";
import { ImpostazioniComponent } from "@app/newComponents/impostazioni/impostazioni.component";
import { ChangePasswordComponent } from "@app/routes/pages/change-password/change-password.component";
import { UserHomeComponent } from "@app/routes/adminPages/user-home/user-home.component";
import { DateRangePickerComponent } from "@app/newComponents/date-range-picker/date-range-picker.component";
import { MapLengendComponent } from "@app/newComponents/map-lengend/map-lengend.component";
import { MyButtonComponent } from "@app/newComponents/my-button/my-button.component";
import { FerieRequestsModalComponent } from "@app/newComponents/ferie-requests-modal/ferie-requests-modal.component";
import { CreateCalendareventModalComponent } from "@app/newComponents/modals/create-calendarevent-modal/create-calendarevent-modal.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToastrModule.forRoot(),
    S1Module.forRoot(),
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
  ],
  providers: [ColorsService],
  declarations: [
    FlotDirective,
    SparklineDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    HighChartComponent,
    CalendarComponent,
    DynamicListComponent,
    RequestComponent,
    AvatarComponent,
    UserCardComponent,
    ModalUserComponent,
    AdminRequestComponent,
    WeekdaysComponent,
    MonthPickerComponent,
    ProfileComponent,
    ImpostazioniComponent,
    DateRangePickerComponent,
    MapLengendComponent,
    UserHomeComponent,
    MyButtonComponent,
    FerieRequestsModalComponent,
    DateRangePickerComponent,

    CreateCalendareventModalComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    ToastrModule,
    FlotDirective,
    SparklineDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    S1Module,
    HighChartComponent,
    CalendarComponent,
    DynamicListComponent,
    RequestComponent,
    AvatarComponent,
    UserCardComponent,
    ModalUserComponent,
    AdminRequestComponent,
    WeekdaysComponent,
    NgSelectModule,
    MonthPickerComponent,
    ProfileComponent,
    ImpostazioniComponent,
    DateRangePickerComponent,
    MapLengendComponent,
    UserHomeComponent,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MyButtonComponent,
    FerieRequestsModalComponent,
    MatDatepickerModule,
    DateRangePickerComponent,
    CreateCalendareventModalComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
