<s1-card>
  <div class="d-flex flex-row justify-content-between px-2 ">
    <div class="d-flex flex-row justify-content-between align-items-center w-100">
      <app-avatar
        class="mt-2 mr-2"
        [name]="request.user.firstName"
      ></app-avatar>

      <div
        *ngIf="showNames else userTemplate"
        class="d-flex flex-column align-items-start justify-content-center">
        <h4 class="card-title m-0" >
          {{ request.user.firstName }} {{ request.user.lastName }}
        </h4>
        
        <h5 class="card-subtitle m-0 text-muted ">
          {{ request.startDate | date : "shortDate" : "" : "it" }} -
          {{ request.endDate | date : "shortDate" : "" : "it" }}
        </h5>
      </div>
    
      <ng-template #userTemplate>
        <div
          class="d-flex align-items-center justify-content-between w-100 ">
          <h6 class="card-subtitle m-0 light text-center">
            {{ request.startDate | date : "shortDate" : "" : "it" }} -
            {{ request.endDate | date : "shortDate" : "" : "it" }}
          </h6>
          <i
           class="icon-close fa-2xl table-icons text-red-color button-icons icon-button"
           (click)="openModal(false)"
          ></i>
      
      </div>
      </ng-template>
      
    </div>

    <div class="d-flex flex-row justify-content-center align-items-center"
      *ngIf="showButtons" >
      <i
        class="icon-check fa-2xl table-icons text-green-color button-icons mr-4 icon-button"
        (click)="openModal(true)"
      ></i>
      <i
        class="icon-close fa-2xl table-icons text-red-color button-icons icon-button"
        (click)="openModal(false)"
      ></i>
    </div>
  </div>
</s1-card>

<!-- MODAL PER ACCETTARE/RIFIUTARE LE FERIE -->

<app-ferie-requests-modal
  #approveReject
  (changes) = "sendChanges()"
  [request]="request"
  (changes)="sendChanges()"

></app-ferie-requests-modal>
