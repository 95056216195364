import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-my-button",
  templateUrl: "./my-button.component.html",
  styleUrls: ["./my-button.component.scss"],
})
export class MyButtonComponent implements OnInit {
  @Input() icon: string = null;
  @Input() text: string;
  @Input() disabled = false;
  @Input() bgColor: string = "blue-color";
  @Input() elementsColor: string = "white";
  @Output() onClick = new EventEmitter<any>();

  click() {
    this.onClick.emit();
  }

  constructor() {}

  ngOnInit(): void {}
}
