<s1-input-label
  [label]="label"
  [hasAction]="hasAction"
  class="bootstrap-theme"
  [showSearch]="showSearch"
>
  <ng-select
    [placeholder]="placeholder"
    class="form-control s1"
    [clearable]="clearable"
    [formControl]="ctrl"
    [items]="itemsList"
    [readonly]="readonly"
    [ngClass]="{ 'is-invalid': !checkIsValid() }"
    [bindValue]="bindValue"
    [multiple]="multiple"
    [placeholder] = "placeholder"
    [virtualScroll]="async ? true : false"
    (clear)="elementSelected('')"
    (change)="elementSelected($event)"
    (search)="searchValue($event)"
    

  >
    <ng-template *ngIf="ngLabelTmpRef" ng-label-tmp let-item="item">
    <ng-template *ngTemplateOutlet="ngLabelTmpRef; context: {item: item}"></ng-template>
    </ng-template>
    <ng-template
      *ngIf="ngOptionTmpRef"
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="search"
    >
      <ng-template
        *ngTemplateOutlet="
          ngOptionTmpRef;
          context: { item: item, index: index, search: search }
        "
      ></ng-template>
    </ng-template>
  </ng-select>
  <ng-container action *ngIf="hasAction">
    <ng-content select="[action]"></ng-content>
  </ng-container>
  <ng-container errors>
    <ng-content select="[errors]"></ng-content>
  </ng-container>
</s1-input-label>
