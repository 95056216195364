<s1-modal #modal [title]="title">

  <form [formGroup]="searchForm">
    <s1-input-text [control]="searchForm.controls.codice" [label]="'s1.standardSearchModal.fields.codice'"></s1-input-text>
    <s1-input-text [control]="searchForm.controls.descrizione" [label]="'s1.standardSearchModal.fields.descrizione'"></s1-input-text>
    <s1-button [type]="s1ButtonType.Search" (onClick)="search()"></s1-button>
  </form>
  <div class="pt-3"></div>
  <s1-card>
    <s1-table #table (pageChanged)="search()">
      <thead header>
        <tr>
          <th> # </th>               
          <th>{{ 's1.standardSearchModal.table.columns.codice' | translate }}</th>
          <th>{{ 's1.standardSearchModal.table.columns.descrizione' | translate }}</th>                                    
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let result of table.config.rows; let i = index" (click)="resultSelected(result)">
          <td>{{ i | s1TableGeneralIndex: table }}</td>
          <td>{{ result.codice }}</td>
          <td>{{ result.descrizione }}</td>
        </tr>
      </tbody>
    </s1-table>
  </s1-card>
  
</s1-modal>
