import { User } from "@app/core/interfaces/admin.interface";

const user: User = JSON.parse(localStorage.getItem("userLogged"));

const Home = {
  text: "Home",
  link: "/home",
  icon: "icon-home",
  section: "HOME"
};

const Dipendenti = {
  text: "Dipendenti",
  link: "/dipendenti",
  icon: "icon-people",
  section: "USER"
};

const CalendarioPersonale = {
  text: "Personale",
  link: "/calendario/:id",
  section: ""
};

const CalendarioGenerale = {
  text: "Generale",
  link: "/calendario/all",
  section: ""
};

const Calendar = {
  text: "Calendario",
  link: "/calendario",
  icon: "icon-calendar",
  submenu: [CalendarioGenerale, CalendarioPersonale],
  section: "CALENDAR"
};

const Vacation = {
  text: "Ferie",
  link: "/ferie",
  icon: "icon-plane",
  section: "VACATION"
};

const Richieste = {
  text: "Richieste",
  link: "/richieste",
  icon: "icon-envelope-letter",
  section: "REQUEST"
};

export const menu = [Home,Dipendenti, Calendar, Vacation, Richieste]
