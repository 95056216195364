import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppService } from "@app/core/services/base.service";
import { formatDate } from "@angular/common";
import { EventInput, EventSourceInput } from "@fullcalendar/core";
import { toLower, toUpper } from "lodash";
import { S1Modal } from "@app/s1";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";
import { RoleService } from "@app/core/services/role-service";
import { Observable } from "rxjs";
import {
  CalendarEvent,
  CalendarEvents,
} from "@app/core/interfaces/admin.interface";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CreateCalendareventModalComponent } from "@app/newComponents/modals/create-calendarevent-modal/create-calendarevent-modal.component";
import { computeInnerRect } from "@fullcalendar/core/internal";
interface User {
  id: string;
  code: number;
  label: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  status: string;
  username: string;
}

@Component({
  selector: "app-calendar-dashboard",
  templateUrl: "./calendar-dashboard.component.html",
  styleUrls: ["./calendar-dashboard.component.scss"],
})
export class CalendarDashboardComponent implements OnInit {
  @ViewChild("select") select: ElementRef;
  @ViewChild("form") form: UntypedFormGroup;
  @ViewChild("message") message: ElementRef;

  date = new Date();

  todayDate: string = formatDate(
    new Date().setDate(new Date().getDate()),
    "yyyy-MM-dd",
    "it-IT"
  );
  tomorrowDate: string = formatDate(
    new Date().setDate(new Date().getDate() + 1),
    "yyyy-MM-dd",
    "it-IT"
  );

  role = "";

  colors = {
    ufficio: "rgba(103, 186, 225, 1)",
    remote: "rgba(216, 133, 56, 1)",
    malattia: "rgba(179, 74, 74, 1)",
    trasferta: "rgba(239, 169, 174, 1)",
    ferie: "rgba(255, 209, 71, 1)",
    festivita: "rgb(241, 33, 237)",
  };

  constructor(
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.role = localStorage.getItem("role");
    this.roleService.setRole(this.role);
    this.activatedRoute.paramMap.subscribe((param) => {
      const id = param.get("id");
      if (id) {
        this.getUserById(id);
      } else {
        this.getAllUsers();
      }
    });
  }

  showDashboard(event): void {
    this.selectedUser = event;
    this.getCalendarevents();
  }

  goBackToGeneral() {
    this.router.navigate(["calendario/:id"]);
    this.selectedUser = null;
  }
  openMailBox() {
    window.location.href = `mailto:${this.selectedUser.email}`;
  }
  redirect() {
    const obj = {
      firstName: this.selectedUser.firstName,
      lastName: this.selectedUser.lastName,
      email: this.selectedUser.email,
      roles: this.selectedUser.roles,
      status: this.selectedUser.status,
      username: this.selectedUser.username,
    };
    localStorage.setItem("userSelected", JSON.stringify(obj));
    this.router.navigate(["/dipendenti"]);
  }

  calendarRedirect() {
    this.selectedUser = null;
    this.router.navigate(["calendario/:id"]);
  }

  getClassForCalendarStatusType(statusType: string): string {
    if (!statusType) {
      return "bg-altro-color";
    }
    return `bg-${statusType.toLowerCase()}-color text-white`;
  }

  //! USERS
  usersList: User[] = [];

  getAllUsers(): void {
    this.appService.getList("/api/admin/users", null).subscribe((data: any) => {
      this.usersList = data.results.map((user) => {
        return {
          id: user.id,
          code: user.id,
          label: `${user.firstName} ${user.lastName}`,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        };
      });
    });
  }

  //! SELECTED USER
  selectedUser: User | null = null;

  getUserById(id): void {
    this.roleService.getSpecificUser(id).subscribe(
      (response: any) => {
        this.selectedUser = {
          id: response.id,
          code: response.id,
          label: `${response.firstName} ${response.lastName}`,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          roles: response.roles,
          status: response.status,
          username: response.username,
        };
        this.getCalendarevents();
      },
      (error) => {
        this.getAllUsers();
      }
    );
  }

  //! CALENDAREVENTS

  dataList: { key; value }[] = [];

  calendareventsMap: CalendarEvents;
  todayCalendarevent: CalendarEvent | null = null;
  tomorrowCalendarevent: CalendarEvent | null = null;
  selectedDate: string = formatDate(new Date(), "yyyy-MM", "it-IT");

  getCalendarevents(): CalendarEvents {
    this.resetDataList();

    this.roleService
      .getCalendarData(this.selectedDate, this.selectedUser.id)
      .subscribe((data: CalendarEvents) => {
        Object.keys(data).forEach((key) => {
          this.updateDataList(key, data[key]);
        });

        this.getVouchers().subscribe((response) => {
          this.dataList.push({ key: "BUONI PASTO", value: response.total });
        });

        Object.keys(data).forEach((key: any) => {
          data[key].forEach((calendar: CalendarEvent) => {
            if (calendar.calendarDay === this.todayDate) {
              this.todayCalendarevent = calendar;
            }
            if (calendar.calendarDay === this.tomorrowDate) {
              this.tomorrowCalendarevent = calendar;
            }
          });
        });
      });

    return this.calendareventsMap;
  }

  updateDataList(key: string, value: any): void {
    if (key !== "PERMESSO") {
      this.dataList.push({ key: key, value: value.length });
    }
  }

  getVouchers(): Observable<any> {
    return this.roleService.getVouchers(
      this.selectedDate,
      this.selectedUser.id
    );
  }

  resetDataList() {
    this.dataList = [];
  }

  //! CALENDARIO
  events: EventSourceInput = [];
  startDate: Date;
  endDate: Date;

  handleCalendarMonth(event): void {
    const formattedDate = formatDate(event, "yyyy-MM", "it");

    this.roleService
      .getCalendarData(formattedDate, this.selectedUser.id)
      .subscribe((data: any) => {
        const events: EventInput[] = [];
        Object.keys(data).forEach((element) => {
          data[element].forEach((day) => {
            const event: EventInput = {
              id: day.id,
              title: day.calendarStatusType,
              start: day.calendarDay,
              end: day.calendarDay,
              allDay: true,
              backgroundColor: this.colors[toLower(day.calendarStatusType)],
              borderColor: this.colors[toLower(day.calendarStatusType)],
            };
            events.push(event);
          });
        });
        this.events = events;
      });
  }

  onYearSelected($event) {
    this.selectedDate = $event;
    this.startDate = $event;
    this.endDate = $event;
    this.getCalendarevents();
  }

  onMonthSelected(event) {
    this.startDate = event.start_date;
    this.endDate = event.end_date;
    this.selectedDate = event.start_date;
    this.getCalendarevents();
  }

  isDateValid: boolean = true;
  checkDate() {
    !isNullOrUndefined(this.startDate) || !isNullOrUndefined(this.endDate)
      ? (this.isDateValid =
          this.startDate.toString() != "Invalid Date" &&
          this.endDate.toString() != "Invalid Date")
      : (this.isDateValid = true);
  }

  isMonthPickerVisible: boolean = false;
  showMonthPicker() {
    this.isMonthPickerVisible = true;
  }

  changeMonthPickerVisibility(event) {
    this.isMonthPickerVisible = !this.isMonthPickerVisible;
  }

  //! MODALS
  modalRef: BsModalRef;

  // MODAL DI CREAZIONE DI UN NUOVO CALENDAREVENT
  openModalProva(date: string, calendarEvent?: CalendarEvent) {
    this.modalRef = this.modalService.show(CreateCalendareventModalComponent, {
      initialState: {
        date: date,
        userId: this.selectedUser.id,
        calendarEvent: calendarEvent, // Passa calendarEvent se stai modificando un evento esistente
      },
    });

    const modalComponent = this.modalRef
      .content as CreateCalendareventModalComponent;
    modalComponent.onClose.subscribe(() => {
      this.getCalendarevents();
      this.handleCalendarMonth(this.selectedDate);
    });
  }

  closeModalProva() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
