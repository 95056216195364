<tr class="row border-bottom border-1" [ngStyle]="{
    backgroundColor:
      request.requestType == 'FERIE'
        ? 'rgba(255, 209, 71, 0.05)'
        : 'rgba(103, 186, 225, 0.05)'
  }">
  <!-- TIPOLOGIA -->
  <td class="col-2 d-flex justify-content-center align-items-center m-0 p-0">
    <i class="icon-large button-icons" [ngClass]="{
        'fa-solid fa-umbrella-beach ': request.requestType == 'FERIE',
        'fa-regular fa-calendar-check': request.requestType != 'FERIE'
      }" [ngStyle]="{
        color:
          request.requestType == 'FERIE'
            ? 'rgba(255, 209, 71, 1)'
            : 'rgba(103, 186, 225, 1)'
      }"></i>
  </td>

  <!-- DIPENDENTE -->
  <td class="col-3">
    <div class="d-flex flex-row justify-content-start align-items-center " >
      <app-avatar [name]="request.user.firstName + ' ' + request.user.lastName" [size]="30" class="mr-3"></app-avatar>
      <div class="d-flex flex-column justify-content-center align-items-start">
        <h4 class="m-0 text">
          {{ request.user.firstName }} {{ request.user.lastName }}
        </h4>
        <p class="m-0 text-sm">{{ request.user.email }}</p>
      </div>
    </div>
  </td>
  <!-- STATO -->
  <td class="{{role==='ADMIN' ? 'col-2' : 'col-3'}} d-flex align-items-center" [ngSwitch]="request.requestStatus">
    <p *ngSwitchCase="'PENDING'" class="m-0 p-0 text-blue-color">
      <b>IN ATTESA</b>
    </p>
    <p *ngSwitchCase="'REJECTED'" class="m-0 p-0 text-red-color">
      <strong> RIFIUTATA</strong>
    </p>

    <p *ngSwitchCase="'APPROVED'" class="m-0 p-0 text-green-color">
      <b> APPROVATA </b>
    </p>
  </td>
  <!-- DATE -->
  <td class="{{role==='ADMIN' ? 'col-2' : 'col-2'}} d-flex align-items-center justify-content-start">
    <div *ngIf="request.requestType == 'FERIE'">
      <div>
        <strong>{{ request.startDate | date : "dd/MM/yyyy" }}</strong>
        <p></p>
        <strong class="m-0 p-0">{{
          request.endDate | date : "dd/MM/yyyy"
          }}</strong>
      </div>
    </div>
    <div *ngIf="request.requestType != 'FERIE'">
      <strong class="m-0 p-0">
        {{ request.startDate | date : "MMMM  yyyy" : "" : "it" | uppercase }}
      </strong>
    </div>
  </td>
  <!-- VISUALIZZA -->
  <td class="col-1 justify-content-center d-flex align-items-center">
    <i class="icon-envelope-open fa-lg table-icons text-blue-color hover:cursor-pointer"
      *ngIf="request.requestStatus != 'REJECTED' && role==='ADMIN'" (click)="linkTo(request)"></i>
    <i class="icon-envelope-open fa-lg table-icons text-blue-color hover:cursor-pointer"
      *ngIf="role==='USER'" ></i>
  </td>
  <!-- OPERAZIONI -->

  <td class="col-2 justify-content-center d-flex align-items-center" *ngIf="role==='ADMIN'">
    <div *ngIf="request.requestStatus == 'PENDING'">
      <i class="icon-check fa-lg table-icons mr-5 text-green-color button-icons" (click)="openModal(true, request)"></i>
    </div>
    <div *ngIf="
        request.requestStatus == 'PENDING' && request.requestType == 'FERIE'
      ">
      <i class="icon-close fa-lg table-icons text-red-color button-icons" (click)="openModal(false, request)"></i>
    </div>
  </td>
</tr>

<!-- MODAL PER ACCETTARE LE CHIUSURE MENSILI -->
<s1-modal #modal title="Chiusura mensile">
  <div class="justify-content-center">
    <p class="text-md mb-5">
      Sei sicuro di voler confermare il mese
      <strong> {{ request.startDate | date : "MMMM" : "" : "it" }}</strong> di
      <strong> {{ request.user.firstName }} {{ request.user.lastName }}</strong>?
    </p>
    <div class="d-flex flex-row justify-content-end">
      <app-my-button class="mr-2" text="INDIETRO" bgColor="blue-color" (click)="closeModal()"
        icon="fa fa-chevron-left fa-md"></app-my-button>
      <app-my-button text="APPROVA" bgColor="green-color" (click)="approveRequest(request.id)"
        icon="fa fa-check fa-md"></app-my-button>
    </div>
  </div>
</s1-modal>

<!-- MODAL PER ACCETTARE/RIFIUTARE LE FERIE -->

<app-ferie-requests-modal #approveReject [request]="request"></app-ferie-requests-modal>