import { Component, EventEmitter, Output, Input } from "@angular/core";

export enum S1ButtonType {
  Normal = "NORMAL",
  Back = "BACK",
  New = "NEW",
  Save = "SAVE",
  Edit = "EDIT",
  Delete = "DELETE",
  Search = "SEARCH",
  Link = "LINK",
  Unlink = "UNLINK",
  Add = "ADD",
  Remove = "REMOVE",
  Next = "NEXT",
  Previous = "PREVIOUS",
  Check = "CHECK",
  Uncheck = "UNCHECK",
  Cancel = "CANCEL",
  Import = "IMPORT",
  Send = "SEND",
  Copy = "COPY",
  View = "VIEW",
  Reload = "RELOAD",
  ExpandMore = "EXPAND_MORE",
  ExpandLess = "EXPAND_LESS",
  ResetFilter = "RESET_FILTER",
  ApplyFilter = "APPLY_FILTER",
}

interface IS1Button {
  class: string;
  classOutline: string;
  icon: string;
  label: string;
}

@Component({
  selector: "s1-button",
  templateUrl: "./s1-button.component.html",
  styleUrls: ["./s1-button.component.scss"],
})
export class S1Button {
  @Input() type: S1ButtonType = S1ButtonType.Normal;
  @Input() label: string;
  @Input() icon: string;
  @Input() isSubmit = false;
  @Input() disabled = false;
  @Input() hidden = false;
  @Input() outline = false;
  @Input() onlyIcon = false;
  @Input() onlyText = false;
  @Input() paddingX: string = "1rem";
  @Input() paddingY: string = "0.5rem";
  @Output() onClick = new EventEmitter<any>();

  s1ButtonType = S1ButtonType;

  styles: { [key in S1ButtonType]: IS1Button } = {
    NORMAL: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "",
      label: "",
    },
    BACK: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-chevron-left",
      label: "s1.button.back",
    },
    NEW: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-plus",
      label: "s1.button.new",
    },
    SAVE: {
      class: "bg-green-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-save",
      label: "s1.button.save",
    },
    EDIT: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-edit",
      label: "s1.button.edit",
    },
    DELETE: {
      class: "bg-red-color text-white",
      classOutline: "btn-outline-danger",
      icon: "fa fa-trash-alt",
      label: "s1.button.delete",
    },
    SEARCH: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-search",
      label: "s1.button.search",
    },
    LINK: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-success",
      icon: "fa fa-link",
      label: "s1.button.link",
    },
    UNLINK: {
      class: "bg-red-color text-white",
      classOutline: "btn-outline-warning",
      icon: "fa fa-unlink",
      label: "s1.button.unlink",
    },
    ADD: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-plus",
      label: "s1.button.add",
    },
    REMOVE: {
      class: "bg-red-color text-white",
      classOutline: "btn-outline-danger",
      icon: "fa fa-trash-alt",
      label: "s1.button.remove",
    },
    NEXT: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-chevron-right",
      label: "s1.button.next",
    },
    PREVIOUS: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-chevron-left",
      label: "s1.button.previous",
    },
    CHECK: {
      class: "bg-green-color text-white",
      classOutline: "btn-outline-success",
      icon: "fa fa-check",
      label: "s1.button.check",
    },
    UNCHECK: {
      class: "bg-red-color text-white",
      classOutline: "btn-outline-danger",
      icon: "fa fa-times",
      label: "s1.button.uncheck",
    },
    CANCEL: {
      class: "bg-red-color text-white",
      classOutline: "btn-outline-secondary",
      icon: "fa fa-undo-alt",
      label: "s1.button.cancel",
    },
    IMPORT: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-file-import",
      label: "s1.button.import",
    },
    SEND: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-paper-plane",
      label: "s1.button.send",
    },
    COPY: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-copy",
      label: "s1.button.copy",
    },
    VIEW: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-warning",
      icon: "fa fa-eye",
      label: "s1.button.view",
    },
    RELOAD: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fas fa-sync",
      label: "s1.button.reload",
    },
    EXPAND_MORE: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-light",
      icon: "fas fa-angle-down",
      label: "s1.button.expand_more",
    },
    EXPAND_LESS: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-light",
      icon: "fas fa-angle-up",
      label: "s1.button.expand_less",
    },
    RESET_FILTER: {
      class: "bg-red-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-trash-alt",
      label: "s1.button.resetFilter",
    },
    APPLY_FILTER: {
      class: "bg-blue-color text-white",
      classOutline: "btn-outline-primary",
      icon: "fa fa-filter",
      label: "s1.button.applyFilter",
    },
  };

  constructor() {}

  click() {
    this.onClick.emit();
  }
}
