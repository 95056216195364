import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { menu } from "@app/routes/menu";
import { MenuService } from "../menu/menu.service";
import { RoleService } from "./role-service";

@Injectable({
  providedIn: "root",
})
export class RoleSelectionService {
  constructor(private menuService: MenuService, private roleService: RoleService) {}

  private _isLogged: BehaviorSubject<any> = new BehaviorSubject(menu);
  isLogged$: Observable<any> = this._isLogged.asObservable();
  role: string = "";

  public saveRole(role: string) {
    localStorage.setItem("role", role);
    this.role = role;
    this._isLogged.next(menu);
  }


}
