import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Calendar, EventSourceInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CreateCalendareventModalComponent } from "../modals/create-calendarevent-modal/create-calendarevent-modal.component";
import { CalendarEvent } from "@app/core/interfaces/admin.interface";
import { formatDate } from "@angular/common";
import { AppService } from '../../core/services/base.service';
import { RoleService } from '../../core/services/role-service';

@Component({
  selector: "app-my-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("calendar", { static: true }) calendarElement: ElementRef;
  @Input() events: EventSourceInput = [];
  @Input() height: number;
  @Output() onModalClose = new EventEmitter<any>()
  @Input() showSelectedCell: boolean = false;
  @Output() dateSelected = new EventEmitter<string>();
  @Output() startMonthSelected = new EventEmitter<string>();
  @Output() endMonthSelected = new EventEmitter<string>();
  @Input() isAdmin: boolean = false; // Input per determinare se l'utente è ADMIN
  @Input() selectDate: boolean = false; // Impostato su false di default
  @Input() showModal: boolean = false; // Impostato su false di default
  @Input() user: any = null
  modalRef: BsModalRef;
  calendar: Calendar;

  constructor(
    private modalService: BsModalService,
    private appService: AppService,
    private roleService: RoleService
  ) {}
  userLogged: any = null

  ngOnInit(): void {
    this.initializeCalendar();
    this.isAdmin = this.role === "ADMIN";
    this.userLogged = JSON.parse( localStorage.getItem("userLogged"))
  }

  get role() {
    return localStorage.getItem("role");
  }

  ngOnDestroy(): void {
    if (this.calendar) {
      this.calendar.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events && !changes.events.firstChange && this.calendar) {
      this.updateEvents();
    }
  }

  initializeCalendar(): void {
    let prevClickedCell: HTMLElement | null = null;

    this.calendar = new Calendar(this.calendarElement.nativeElement, {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      editable: false,
      contentHeight: this.height,
      dateClick: ((info) => {
      if (this.showSelectedCell) {
        if (prevClickedCell) {
          prevClickedCell.style.backgroundColor = '';
        }
        info.dayEl.style.backgroundColor = 'rgba(233, 231, 231, 1)';
        prevClickedCell = info.dayEl;
      }
      this.onDateClick(info)

    }
    ),
      dayMaxEvents: 1,
      firstDay: 1,
      locale: "it",
      events: this.events,
      eventBackgroundColor: "rgba(255, 209, 71, 1)",
      eventBorderColor: "rgba(255, 209, 71, 1)",
      eventTextColor: "black",
      datesSet: () => this.onDatesSet(),
    });

    this.calendar.render();
  }

  updateEvents(): void {
    if (this.calendar) {
      this.calendar.removeAllEvents();
      this.calendar.addEventSource(this.events);
      this.calendar.render();
    }
  }

  onDateClick(info: DateClickArg): void {
    console.log("DATE CLICK");
    const currentDate = formatDate(new Date(), "yyyy-MM-dd", "it");
    const selectedDate = formatDate(new Date(info.date), "yyyy-MM-dd", "it");
    this.emitDateSelected(selectedDate)
    
    if ((this.isAdmin) || (!this.isAdmin && selectedDate >= currentDate)) {
      console.log("OPEN MODAL");
      const formattedDate: string = info.dateStr;
      this.emitDateSelected(formattedDate);
      this.openCreateEventModal(info);
      if (this.showSelectedCell) {
        const dayEl: HTMLElement = info.dayEl;
        dayEl.style.backgroundColor = "rgba(233, 231, 231, 1)";
      }
    }
  }

  emitDateSelected(formattedDate: string): void {
    this.dateSelected.emit(formattedDate);
  }

  onDatesSet(): void {
    const currentDate = this.calendar.getDate();
    const formattedStartMonth = this.formatDate(currentDate);
    const formattedEndMonth = this.formatEndDate(currentDate);

    this.startMonthSelected.emit(formattedStartMonth);
    this.endMonthSelected.emit(formattedEndMonth);
  }

  openCreateEventModal(info: DateClickArg): void {
    const selectedDate: string = info.dateStr;
    console.log("SELECTED DATE", selectedDate);
    console.log("MODAL APERTA COME ", this.role)

    !this.isAdmin ? this.user = JSON.parse(localStorage.getItem("userLogged")) : null
    this.roleService.getCalendarDay(selectedDate, this.isAdmin ? this.user.id : null)
      .subscribe((response: any) => {
        console.log(response);
        const calendarEvent = response.results.length > 0 ? response.results[0] : null;

        if (!calendarEvent || (calendarEvent.calendarStatusType !== "FERIE" && calendarEvent.calendarStatusType !== "FESTIVITA")) {
          this.modalRef = this.modalService.show(CreateCalendareventModalComponent, {
            initialState: {
              date: selectedDate,
              calendarEvent: calendarEvent,
              userId: this.user.id
            },
          });

          this.modalRef.content.onClose.subscribe(() => {
            this.updateEvents();
            this.initializeCalendar()
            this.onModalClose.emit("")
          });
        }
      });
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    return `${year}-${month}`;
  }

  formatEndDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 2)).slice(-2);
    return `${year}-${month}`;
  }

  get userId(): string {
    const user = JSON.parse(localStorage.getItem("userLogged"));
    return user.id;
  }

  selectedDate(info: any, cell: any) {
    if (this.selectDate === true) {
      const year: number = info.date.getFullYear();
      const month: string = ("0" + (info.date.getMonth() + 1)).slice(-2);
      const day: string = ("0" + info.date.getDate()).slice(-2);
      const formattedDate: string = `${year}-${month}-${day}`;

      if (this.showSelectedCell) {
        if (cell) {
          cell.style.backgroundColor = "";
        }
        info.dayEl.style.backgroundColor = "rgba(233, 231, 231, 1)";
        cell = info.dayEl;
      }

      this.dateSelected.emit(formattedDate);
    }
  }
}
