<main class="d-flex flex-column align-items-center" [ngClass]="class">
  <div >
    <mat-form-field>
      <mat-label></mat-label>

      <div class="date-range-container">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" #startDateInput (dateChange)="onStartDateChange($event)">
          <input matEndDate placeholder="End date" (dateChange)="onEndDateChange($event)" #endDateInput>
        </mat-date-range-input>

        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      </div>

      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <small class="text-danger" *ngIf="!isInFuture && isDateSelected">La data deve essere nel futuro</small>
</main>


