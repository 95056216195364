import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AppService } from "@app/core/services/base.service";
import { CalendarComponent } from "@app/newComponents/calendar/calendar.component";
import { S1Modal, S1ModalSizes } from "@app/s1";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { Calendar, EventInput, EventSourceInput } from "@fullcalendar/core";
import { catchError } from "rxjs/operators";
import swal from 'sweetalert2';

@Component({
  selector: "app-festivita",
  templateUrl: "./festivita.component.html",
  styleUrls: ["./festivita.component.scss"],
})
export class FestivitaComponent implements OnInit {
  @ViewChild("calendario") calendar: CalendarComponent;
  @ViewChild("modal") modal: S1Modal;   

  date: string = "";
  showError: boolean = false;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.getHoldidays()
  }

  getHoldidays(){
    this.appService.getAll("/api/admin/calendar/holiday")
      .subscribe((response: any) => {
        this.createCalendarEvents(response.results) 
      },(error: any)=> {
        swal.fire({
          icon:"error",
          title: "ERRORE",
          text: "Errore nel recupero dei dati, riprova più tardi. "
        })
      })
  }

  @ViewChild("calendar") calendarComponent: FullCalendarComponent
  events: EventSourceInput = []

  createCalendarEvents(data){
    const events: EventInput[] = []
    console.log(data)
    data.map(data=> {

      const event: EventInput = {
        id: data.id,
        title: "FESTIVITA",
        start: data.calendarDay,
        end: data.calendarDay,
        allDay: true,
        backgroundColor: 'rgb(241, 33, 237)',
        borderColor: 'rgb(241, 33, 237)'
  
      };  
      events.push(event)
    })

    this.events = events
    this.calendarComponent.events = this.events
  }
  onConfirm() {
    if (this.date != "") {
      const body = { calendarDay: this.date };
      this.modal.open(S1ModalSizes.LG);
    } else {
      this.showError = true;
    }
  }

  onReset() {
    this.date = "";
    console.log(this.date);
    this.showError = false;
  }

  onDateSelected($event) {
    this.date = $event;
    this.showError = false;
  }

  closeModal() {
    this.modal.close();
  }

  createHoliday() {
    const body = { calendarDay: this.date };
    this.appService.newElement("/api/admin/calendar/holiday", body).subscribe(() => this.getHoldidays());
    this.modal.close();
  }
}
