<div
  class="text-center font-weight-bold home-text-big"
  *ngIf="showTitle"
>
  <h3>
    {{ title }}
    
  </h3>
</div>
<ul class="home-ul w-100 p-0">
  <ng-container *ngFor="let item of items">
    <li
      class="border d-flex justify-content-between align-items-center home-ul-li hover-overlay bg-{{
        item.key.toLowerCase().replace(' ', '')
      }}-color rounded cube text-white col"
    >
      <a
        class="home-ul-li-link d-flex justify-content-between align-items-center w-100" [ngClass]="{'home-no-hover' : hover}"
        (click)="showModal(item.key)"
      >
        <h4 class="font-weight-bold home-text-big my-auto ml-2 col-7">
          {{ item.key }}
        </h4>
        <span class="home-ul-li-span col-5 text-end">{{ item.value }}</span>
      </a>
    </li>
  </ng-container>
</ul>
