import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../../core/services/base.service';
import { LoggingService } from '@app/core/services/log.service';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { first, isDate, isNull, isNumber, isObject } from 'lodash';
import { S1Table } from '@app/s1';
import { RoleService } from '@app/core/services/role-service';
import swal from 'sweetalert2';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-richieste',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {

  constructor(private appService: AppService, private logService: LoggingService, private roleService : RoleService) { }
  role : string = ''  
  @ViewChild("table") table: S1Table
  startDate: string;
  endDate: string;
  selectedStatus: string = null;
  selectedUser: any = null;
  allRequests: boolean = false;
  vacationRequests: boolean = false;
  closingRequests: boolean = false;

  usersList = [];
  requestList: any = [];
  statusList: any[] = [
    { id: "APPROVED", code: "APPROVED", label: "APPROVATE" },
    { id: "PENDING", code: "PENDING", label: "IN ATTESA" },
    { id: "REJECTED", code: "REJECTED", label: "RIFIUTATE" }
  ];

  ngOnInit(): void {
    this.role = localStorage.getItem('role')
    this.roleService.setRole(this.role)
    this.getAllRequests();
    this.getAllUsers();
  }

  handleModalEvent(){
    this.getAllRequests()
  }

  getAllRequests(){
    this.roleService.getRequestsFiltered(null, null, null, null, null)
      .subscribe((data: any) => {
        this.requestList = data.results
        setTimeout(() => {
          this.table.updateData(this.requestList)
        },1)
      })
  }

  filterByVacation() {
    this.vacationRequests = !this.vacationRequests;
    this.closingRequests = false; // Ensure closingRequests is deactivated
    this.allRequests = false; // Ensure allRequests is deactivated
  }
  
  filterByClosing() {
    this.closingRequests = !this.closingRequests;
    this.vacationRequests = false; // Ensure vacationRequests is deactivated
    this.allRequests = false; // Ensure allRequests is deactivated

  }
  
  filterByAll() {
    this.allRequests = !this.allRequests;
    this.vacationRequests = false; // Ensure vacationRequests is deactivated
    this.closingRequests = false; // Ensure closingRequests is deactivated

  }
  

  filterByStatus(event) {
    this.selectedStatus = event.code;
  }

  filterByUser(event) {
    this.selectedUser = event.id;
  }

  getAllUsers(): void {
    this.appService.getList("/api/admin/users", null)
      .subscribe((data: any) => {
        this.usersList = data.results.map(user => ({
          id: user.id,
          code: user.id,
          label: user.lastName + ' ' + user.firstName
        }));
      });
  }


  filterList() {
var type = null

    if (this.vacationRequests) {
      type = "FERIE&";
    } else if (this.closingRequests) {
      type = "CHIUSURA&";
    }
  

    this.roleService.getRequestsFiltered(type, this.startDate, this.endDate, this.selectedStatus, this.selectedUser)
      .subscribe((data: any) => {
        this.requestList = data.results;
        this.table.updateData(this.requestList)
        console.log(this.requestList)
      });
  }
  
  
  isDateValid: boolean = true
  checkDate(){
    !isNullOrUndefined(this.startDate) || !isNullOrUndefined(this.endDate) ? (
      this.isDateValid = this.startDate.toString() != "Invalid Date"  && this.endDate.toString() != "Invalid Date"
    ) : this.isDateValid = true
  }

  isMonthPickerVisible: boolean = false
  showMonthPicker(){
    this.isMonthPickerVisible = true;
  }
  handleMonthRangeSelected(event){
    this.startDate = event.start_date
    this.endDate = event.end_date
  }
  
  changeMonthPickerVisibility(event){
    this.isMonthPickerVisible = !this.isMonthPickerVisible
  }
  sendMonthlyRequest(){
    const now = new Date();
    const firstDay = formatDate(new Date(now.getFullYear(), now.getMonth(), 1), "yyyy-MM-dd", "It");
    const lastDay = formatDate(new Date(now.getFullYear(), now.getMonth() + 1, 0),"yyyy-MM-dd","it");
    const body = {
      startDate: firstDay,
      endDate: lastDay,
      requestType: "CHIUSURA"
    }
    console.log(body)
    this.appService.newElement("/api/user/request", body)
      .subscribe((response: any) => {
        console.log(response)
        swal.fire({
          icon: isObject(response.data) ? 'success' : "error",
          title: isObject(response.data) ? "SUCCESSO" : "ATTENZIONE",
          text: isObject(response.data) ? "Richiesta inviata con successo!" : response.data
        })
      })
  }
}
