import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { NgModule } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppComponent } from "./app.component";

import { CoreModule } from "./core/core.module";
import { LayoutModule } from "./layout/layout.module";
import { SharedModule } from "./shared/shared.module";
import { RoutesModule } from "./routes/routes.module";

import { AppService } from "./core/services/base.service";
import { LoggingService } from "./core/services/log.service";
import { AuthService } from "./core/services/auth.service";
import { UtilsService } from "./core/services/utils.service";
import { LocalizedDatePipe } from "./localized-date.pipe";
import { RequestService } from "./core/services/request.service";
import { CustomDateAdapter } from "./custom-date-adapter";
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';


// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, LocalizedDatePipe],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule.forRoot(),
    SharedModule.forRoot(),
    RoutesModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppService,
    LoggingService,
    AuthService,
    UtilsService,
    RequestService,
    {provide: DateAdapter, useClass: CustomDateAdapter },
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT' } 
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
