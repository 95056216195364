import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';

import { IS1RicercaStandardResult } from '../../services/s1-standard-search.service';
import { S1Table } from '../s1-table/s1-table.component';
import { S1StandardSearchModal, IS1StandardSearchModalConfig } from '../s1-standard-search-modal/s1-standard-search-modal.component';
import { S1ButtonType } from '../s1-button/s1-button.component';

@Component({
  selector: 's1-standard-manage-section',
  templateUrl: './s1-standard-manage-section.component.html',
  styleUrls: ['./s1-standard-manage-section.component.scss']
})
export class S1StandardManageSection implements OnInit {

  @Input() config: IS1StandardSearchModalConfig
  @Input() elements: IS1RicercaStandardResult[]
  @Output() elementsChange = new EventEmitter(); //two-way data binding
  @Input() headerStyle: string = "bg-dark text-light";

  @ViewChild('table') table: S1Table;
  @ViewChild('standardSearchModal') standardSearchModal: S1StandardSearchModal;

  s1ButtonType = S1ButtonType;

  constructor() { }

  ngOnInit(): void { }

  searchElement() {

    this.standardSearchModal.open(this.config)

  }

  addElement(element: IS1RicercaStandardResult) {

    if (!this.elements) {
      this.elements = []
    }

    this.elements.push(element)
    this.table.updateData(this.elements)
    this.elementsChange.emit(this.elements)
    
  }

  removeElement(elementToRemove: IS1RicercaStandardResult) {

    this.elements = this.elements.filter(element => element.codice != elementToRemove.codice)
    this.table.updateData(this.elements)
    this.elementsChange.emit(this.elements)

  }

}