import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() name: string; // Input property to receive the name from parent component
  @Input() size: number = 35; // Input property for size with a default value
  
  avatarUrl: string;

  constructor() { }

  ngOnInit(): void {
    this.generateAvatar();
  }

  generateAvatar(): void {
    this.avatarUrl = `https://ui-avatars.com/api/?name=${this.name}&size=${this.size}`;
  }
}