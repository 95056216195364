import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoggingService } from './log.service';
import { Observable, throwError } from 'rxjs';
import { SettingsService } from '../settings/settings.service';



@Injectable()
export class RequestService {

constructor(private http: HttpClient, private logger: LoggingService, private router: Router, private settings: SettingsService) {}

    approveRequest(id : number): Observable<any>{
        const path = environment.restBaseUrl + '/api/admin/request/approve/' + id;
        console.log(path)
     
            return this.http
              .post<any>(path, null ,this.getRequestOptionArgs())
              .pipe(map(
              (response: HttpResponse<any>) => {
                const outcome = response['outcome'];
                const dataResponse = response['data'];
                if (outcome.success === true) {
                  this.logger.log("Service:", "SUCCESS", 200);
                  return dataResponse;
                } else {
                  this.logger.log("Service:", "FAILURE", 200);
                  outcome.message = this.settings.manageErrorMsg(outcome);
                  return outcome;
                }
              }
            ), catchError((errorResponse: HttpErrorResponse) => {
                this.logger.log("Error Response:", errorResponse, 200);
                let res: any = errorResponse.error;
                if (res.outcome.code === "0005" || res.outcome.code === "0007") {
                  this.settings.sessionExpired();
                }
                return throwError(errorResponse.error);
              }
            ));

        }

    rejectRequest(id : number, url): Observable<any>{
        const path = environment.restBaseUrl + url + id;
        console.log(path)
        
            return this.http
                .post<any>(path, null ,this.getRequestOptionArgs())
                .pipe(map(
                (response: HttpResponse<any>) => {
                const outcome = response['outcome'];
                const dataResponse = response['data'];
                if (outcome.success === true) {
                    this.logger.log("Service:", "SUCCESS", 200);
                    return dataResponse;
                } else {
                    this.logger.log("Service:", "FAILURE", 200);
                    outcome.message = this.settings.manageErrorMsg(outcome);
                    return outcome;
                }
                }
            ), catchError((errorResponse: HttpErrorResponse) => {
                this.logger.log("Error Response:", errorResponse, 200);
                let res: any = errorResponse.error;
                if (res.outcome.code === "0005" || res.outcome.code === "0007") {
                    this.settings.sessionExpired();
                }
                return throwError(errorResponse.error);
                }
            ));

        }


      getRequestOptionArgs(): any {
    
        const token = localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
    
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'X-Auth-Token': token
          })
        };
        return httpOptions;
      }

}