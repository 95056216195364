import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { SettingsService } from "../../../core/settings/settings.service";

import { LoggingService } from "../../../../app/core/services/log.service";
import { AuthService } from "../../../../app/core/services/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import swal from "sweetalert2";

@Component({
  selector: "app-recover",
  templateUrl: "./recover.component.html",
  styleUrls: ["./recover.component.scss"],
})
export class RecoverComponent implements OnInit {
  @ViewChild("formRecover") recoverForm: NgForm;

  // string for errorMessage service
  msgTranslation;
  errorTranslation;
  errorMessage;
  logger: any;

  constructor(
    public settings: SettingsService,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    this.errorTranslation = this.translate.get("error");
    this.msgTranslation = this.translate.get("msg");
  }

  onSubmit(): void {
    const email = this.recoverForm.value.email;

    swal.fire({
      title: this.msgTranslation.loading,
      didOpen: () => {
        swal.showLoading();
      },
    });

    this.authService.forgotPassword(email).subscribe(
      (response) => {
        swal.close();
        if (response.responseCode === 200) {
          localStorage.setItem("email", email);
          this.router.navigate(["/changepassword"]);
          swal.close();
        } else {
          console.log("Error", response.outcome);
          swal.fire(
            this.msgTranslation.error,
            this.settings.manageErrorMsg(response.outcome),
            "error"
          );
        }
      },
      (error) => {
        swal.fire(
          this.msgTranslation.error,
          "L'email non è associata a nessun account",
          "error"
        );
      }
    );
  }

  ngOnInit() {}
}
