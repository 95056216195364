import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as Highcharts from "highcharts";

@Component({
  selector: "app-column-chart",
  templateUrl: "./high-chart.component.html",
  styleUrls: ["./high-chart.component.scss"],
})
export class HighChartComponent implements OnChanges {
  @Input() items: { key: string; value: number }[] = [];
  @Input() height: number = 300;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && !changes.items.firstChange) {
      this.renderChart();
    }
  }

  private renderChart(): void {
    const colorsMap = {
      ufficio: "rgba(103, 186, 225, 1)",
      remote: "rgba(216, 133, 56, 1)",
      malattia: "rgba(179, 74, 74, 1)",
      trasferta: "rgba(239, 169, 174, 1)",
      ferie: "rgba(255, 209, 71, 1)",
      altro: "rgb(124, 124, 124)",
      listview: "rgba(233, 231, 231, 1)",
      red: "rgba(235, 119, 81, 1)",
      green: "rgba(91, 184, 104, 1)",
      blue: "rgba(65, 115, 176, 1)",
      festivita: "rgb(241, 33, 237)"
    };

    const totalSum = this.items.reduce((sum, item) => sum + item.value, 0);

    // Convert each value to percentage
    const chartData: Highcharts.PointOptionsObject[] = this.items.map(
      (item) => ({
        name: item.key,
        label: item.key,
        y: (item.value / totalSum) * 100, // Calculate percentage and round to 2 decimal places
        color: colorsMap[item.key.toLowerCase()],
      })
    );

    // @ts-ignore
    Highcharts.chart("container", {
      chart: {
        type: "column",
        height: this.height ,
        
      },
      title: {
        text: "Presenze",
      },
      tooltip: {
        valueSuffix: "%",
        valueDecimals: 2,
      },
      series: [
        {
          type: "column",
          name: "",
          color: 'transparent',
          colorByPoint: false,
          allowPointSelect: false,
          data: chartData, // Pass chartData directly here
        },
        
      ],
      yAxis: [{
       labels : {format : "{text} %"},
       title: ""
      }],
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '11.5px',
            textAlign: 'center',
            fontWeight: 'bold'
          }
        }
      }
    });
  }
}
