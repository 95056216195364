<div *ngFor="let request of notifications" class="m-1 notification">
  <div class="card m-3">
    <div class="row no-gutters">
      <div class="col-auto w-25 m-auto">
        <!-- Immagine del profilo -->
        <i class="fas" [ngClass]="{'icon-plane fa-2x': request.requestType === 'FERIE', 'icon-calendar': request.requestType === 'CHIUSURA' }" class=" fa-3x m-4"></i>
        <!-- Use ngClass to conditionally apply classes based on the request type -->
      </div>
      <div class="col w-75 ml-4">
        <div class="card-body">
          <!-- Nome e cognome -->
          <h5 class="card-title">{{request.user.firstName}} {{request.user.lastName}}</h5>
          <!-- Email -->
          <p class="card-text">{{request.user.email}}</p>
          <!-- Tipo di richiesta -->
          <p class="card-text">{{request.requestType}}</p>
          <!-- Icona per il tipo di richiesta -->
        </div>
      </div>
    </div>
  </div>
</div>

  
  