import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core';
import { range } from 'lodash';

@Component({
  selector: 'month-picker',
  templateUrl: 'month-picker.component.html',
  styleUrls: ['month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {

  @Output() monthRangeSelected = new EventEmitter<{ start_date: string, end_date: string }>();
  @Output() displayEvent = new EventEmitter<any>();
  @Output() year = new EventEmitter<string>();
  @Input() selectionMode: 'single' | 'multiple' = 'multiple'; // default to multiple selection mode

  display: boolean = true;
  currentYearIndex: number;
  years: Array<number>;
  months: Array<string>;
  monthsData: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean,
    isSelected: boolean // Add a property to track selection
  }>;
  rangeIndexes: Array<number>;
  monthViewSlicesIndexes: Array<number>;
  monthDataSlice: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean,
    isSelected: boolean
  }>;
  globalIndexOffset: number;

  constructor() {
    this.currentYearIndex = 0;
    this.years = [];
    this.months = [];
    this.monthsData = [];
    this.rangeIndexes = [null, null];
    this.monthViewSlicesIndexes = [];
    this.monthDataSlice = [];
    this.globalIndexOffset = 0;
  }

  ngOnInit() {
    this.initYearLabels();
    this.initMonthLabels();
    this.initMonthsData();
    this.initViewSlices();
    this.initRangeIndexes();
    this.currentYearIndex = this.years.findIndex(year => year === (new Date()).getFullYear());
    this.sliceDataIntoView();
  }

  onClick(indexClicked: number) {
    if (this.selectionMode === 'single') {
      this.resetRangeSelection();
      this.rangeIndexes[0] = this.globalIndexOffset + indexClicked;
      this.rangeIndexes[1] = this.globalIndexOffset + indexClicked;
      this.updateMonthRange();
      this.emitRange();
      this.close()
    } else {
      if (this.rangeIndexes[0] === null) {
        this.rangeIndexes[0] = this.globalIndexOffset + indexClicked;
      } else if (this.rangeIndexes[1] === null) {
        this.rangeIndexes[1] = this.globalIndexOffset + indexClicked;
        this.rangeIndexes.sort((a, b) => a - b);
        this.updateMonthRange();
        this.emitRange();
        this.close()
      } else {
        this.resetRangeSelection();
        this.onClick(indexClicked);

      }
    }
  }

  emitRange() {
    const fromMonthYear = this.monthsData[this.rangeIndexes[0]];
    const toMonthYear = this.monthsData[this.rangeIndexes[1]];
    const startDate = `${fromMonthYear.monthYear}-${String(this.getMonthNumber(fromMonthYear.monthName)).padStart(2, '0')}`;
    const endDate = `${toMonthYear.monthYear}-${String(this.getMonthNumber(toMonthYear.monthName)).padStart(2, '0')}`;
    this.monthRangeSelected.emit({ start_date: startDate, end_date: endDate });
  }

  emitData(data: any) {
    this.monthRangeSelected.emit(data);
  }

  sliceDataIntoView() {
    this.globalIndexOffset = this.monthViewSlicesIndexes[this.currentYearIndex];
    this.monthDataSlice = this.monthsData.slice(this.globalIndexOffset, this.globalIndexOffset + 12);
  }

  incrementYear() {
    if (this.currentYearIndex < this.years.length - 1) {
      this.currentYearIndex++;
      this.sliceDataIntoView();
    }
  }

  decrementYear() {
    if (this.currentYearIndex > 0) {
      this.currentYearIndex--;
      this.sliceDataIntoView();
    }
  }

  initRangeIndexes() {
    this.rangeIndexes = [null, null];
  }

  initMonthsData() {
    this.monthsData = [];
    this.years.forEach(year => {
      this.months.forEach(month => {
        this.monthsData.push({
          monthName: month,
          monthYear: year,
          isInRange: false,
          isLowerEdge: false,
          isUpperEdge: false,
          isSelected: false // Initialize the selection property
        });
      });
    });
  }

  initYearLabels() {
    const currentYear = (new Date()).getFullYear();
    const range = (start: number, stop: number, step: number) => {
      return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    };
    this.years = range(currentYear - 1, currentYear + 5, 1);
  }

  initMonthLabels() {
    this.months = new Array(12).fill(0).map((_, i) => {
      return new Date(0, i).toLocaleString(undefined, { month: 'short' });
    });
  }

  initViewSlices() {
    this.monthViewSlicesIndexes = this.years.map((_, index) => index * 12);
  }

  updateMonthRange() {
    this.monthsData.forEach((month, index) => {
      month.isInRange = this.rangeIndexes[0] <= index && index <= this.rangeIndexes[1];
      month.isLowerEdge = index === this.rangeIndexes[0];
      month.isUpperEdge = index === this.rangeIndexes[1];
      month.isSelected = this.rangeIndexes.includes(index); // Update the selection state
    });
  }

  resetRangeSelection() {
    this.initRangeIndexes();
    this.monthsData.forEach(month => {
      month.isInRange = false;
      month.isLowerEdge = false;
      month.isUpperEdge = false;
      month.isSelected = false;
    });
    this.sliceDataIntoView();
  }

  getMonthNumber(monthName: string): number {
    return this.months.indexOf(monthName) + 1;
  }

  close() {
    this.display = false;
    this.displayEvent.emit("");
  }

  resetDates() {
    this.monthRangeSelected.emit({ start_date: "", end_date: "" });
    this.close();
  }

  onYearClick(year: number) {
    console.log(year);
    this.year.emit(year.toString());
    this.close();
  }
}
