import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-impostazioni",
  templateUrl: "./impostazioni.component.html",
  styleUrls: ["./impostazioni.component.scss"],
})
export class ImpostazioniComponent implements OnInit, AfterViewInit {
  constructor(private formBuilder: FormBuilder, private router: Router) {}

  private _show: string = "menu";
    

  get show(): string {
    return this._show;
  }

  set show(value: string) {
    if (this._show !== value) {
      this._show = value;
      this.onShowChange();
    }
  }

  user: any = {};
  form: UntypedFormGroup | null = null;
  selectedShow: TemplateRef<any>;

  @ViewChild("menu") menu!: TemplateRef<any>;
  @ViewChild("profilo") profilo!: TemplateRef<any>;

  @Output() close = new EventEmitter<any>();

  ngOnInit(): void {
    this.show = 'profilo';
    const userData = localStorage.getItem("userLogged");
    this.user = userData ? JSON.parse(userData) : {};

    if (this.user) {
      this.initForm();
    }
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      this.selectedShow = this.getShowRef();
    });
  }

  getShowRef() {
    return this[this.show];
  }

  onShowChange() {
    console.log(`show has changed to: ${this.show}`);
    this.selectedShow = this.getShowRef();
  }

  initForm() {

    if (!this.user) {
      return;
    }

    this.form = this.formBuilder.group({
      username: [this.user.username, Validators.required],
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      status: [this.user.status],
      roles: [this.user.roles, Validators.required],
    });
  }

  

  closeModal() {
    this.show = "menu";
    this.close.emit();
  }
  navigateToHoliday(){
    this.router.navigate(['/festivita'])
    this.close.emit()

}
}
