import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "@app/core/interfaces/admin.interface";
import { MenuService } from "@app/core/menu/menu.service";
import { RoutesModule } from "@app/routes/routes.module";
import { RoleSelectionService } from "@app/core/services/roleSelection-service";


@Component({
  selector: "app-role-selection",
  templateUrl: "./role-selection.component.html",
  styleUrls: ["./role-selection.component.scss"],
})

export class RoleSelectionComponent implements OnInit {
  user: User;
  roles: any[] = [];
 

  constructor(private router: Router, public menuService: MenuService, private roleSelectionService : RoleSelectionService) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('userLogged'));
    this.roles = this.user.roles;
  }

  goToHome($event) {

    this.roleSelectionService.saveRole($event)
    console.log('Role stored in localStorage:', localStorage.getItem('role'));
    this.router.navigate(['/home']);
  }
}
