import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Injectable()
export class UtilsService {

  constructor() { }

  /** Method that checks if the page was opened in view mode */
  isViewMode(pageTypeAction: any, viewAction: any): boolean {
    return pageTypeAction === viewAction;
  }

  /** Method that checks if a certain control was already touched */
  checkIsTouched(control: AbstractControl): boolean {
    return control?.touched;
  }

   generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
