import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 's1-button-expand',
  templateUrl: './s1-button-expand.component.html',
  styleUrls: ['./s1-button-expand.component.scss']
})
export class S1ButtonExpand implements OnInit {

  @Input() expand: boolean = false
  @Output() expandChange = new EventEmitter<boolean>()

  constructor() { }

  ngOnInit(): void { }

  toggle() {
    this.expand = !this.expand
    this.expandChange.emit(this.expand)
  }

}
