
<div class="container bg-white rounded px-3 d-flex flex-row ps-2">
    <button class="icon-arrow-left arrows" (click)="prevWeek()"></button>
    <div class="w-100 justify-content-center text-center">
      <small class=" text-uppercase m-auto text-center">{{endMonth}} {{year}}</small>
      <span class=" d-block"><i>{{startDay}}</i> {{startDayNumber}} - <i>{{endDay}}</i> {{endDayNumber}}</span>
    </div>
    <button class="icon-arrow-right arrows" (click)="nextWeek()"></button>
</div>


