<div class="jumbotron rounded col d-flex flex-column align-items-center">
  <div
    class="row w-100 d-flex justify-content-between align-items-center mb-3 pb-3 border-bottom"
  >
    <h3>Dipendenti</h3>
    <app-my-button
      text="Crea Utente"
      (onClick)="openModal('create', null)"
      icon="fa fa-plus"
    >
    </app-my-button>
  </div>

  <div class="d-flex align-items-start w-100 justify-content-center mb-3">
    <div class="col mr-3">
      <p class="m-0">Nome:</p>
      <input
        type="text"
        class="form-control"
        placeholder="Cerca per nome"
        [name]="nameValue"
        [(ngModel)]="nameValue"
        (ngModelChange)="onNameValueChange($event)"
      />
    </div>
    <div class="col mr-3">
      <p class="m-0">Ruolo:</p>
      <s1-input-select
        [itemsList]="roles"
        label=""
        [placeholder]="'Seleziona il ruolo'"
        [showSearch]="false"
        [name]="'roles'"
        (onSelect)="onRoleValueChange($event.code)"
      ></s1-input-select>
    </div>
    <div class="col">
      <p class="m-0">Stato:</p>
      <s1-input-select
        [itemsList]="status"
        [placeholder]="'Seleziona lo stato'"
        label=""
        [name]="'status'"
        [showSearch]="false"
        (onSelect)="onStatusValueChange($event.code)"
      ></s1-input-select>
    </div>
  </div>

  <div class="w-100 m-auto">
    <s1-table #table [onlinePagination]="false">
      <thead header class="col" >
        <tr class="header">
          <th class=" text-center">#</th>
          <th class="">Info</th>
          <th class="">Stato</th>
          <th class=""></th>
          <th class=""></th>
          <th class=""></th>
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let user of table.config.rows; let i = index">
          <td class="p-0 text-center ">
            {{ (i + 1) }}
          </td>
          <td class="py-4 d-flex m-0 justify-content-left align-items-center ">
            <div
              class="mr-3 p-0 d-flex justify-content-center align-items-center "
            >
              <app-avatar
                [name]="user.firstName"
                [size]="60"
                class="p-0"
              ></app-avatar>
            </div>
            <div>
              <p class="m-0 text-bold" style="font-size: large">
                {{ user.firstName + " " + user.lastName }}
              </p>
              <p class="text-sm m-0">{{ user.email }}</p>
            </div>
          </td>

          <td class="p-0 text-left ">
            <ng-container [ngSwitch]="user.status">
              <ng-container *ngSwitchCase="'ACTIVE'">
                <p class="text-green-color p-3"><strong>ATTIVO</strong></p>
              </ng-container>
              <ng-container *ngSwitchCase="'CREATED'">
                <p class="text-blue-color p-3"><strong>CREATO</strong></p>
              </ng-container>
              <ng-container *ngSwitchCase="'DISABLED'">
                <p class="text-red-color p-3"><strong>DISATTIVATO</strong></p>
              </ng-container>
            </ng-container>
          </td>
          <td class="p-0 text-center ">
            <a *ngIf="user.status != 'DISABLED'">
              <i
                class="icon-user hoverable fa-lg table-icons text-blue-color"
                (click)="openModal('show', user)"
              ></i
            ></a>
          </td>
          <td class="p-0 text-center">
            <a *ngIf="user.status != 'DISABLED'">
              <i
                class="icon-pencil fa-lg table-icons text-blue-color"
                (click)="openModal('update', user)"
              ></i
            ></a>
          </td>
          <td class="p-0 text-center ">
            <a *ngIf="user.status != 'DISABLED'">
              <i
                class="far fa-trash-alt fa-lg table-icons text-red-color"
                (click)="openModal('delete', user)"
              ></i
            ></a>
          </td>
        </tr>
      </tbody>
    </s1-table>
  </div>
</div>

<s1-modal #modal [deleteUser]="true">
  <app-modal-user
    [user]="user"
    [modalType]="modalType"
    (action)="reloadPage()"
    (close)="closeModal()"
  >
  </app-modal-user>
</s1-modal>
