<div class="input-group" [ngClass]="{ 'mb-2': hasMargin }">
  <div class="input-group-prepend" *ngIf="label" aria-placeholder="Cerca">
    <span class="input-group-text">{{ label | translate }}</span>
  </div>
  <a class="nav-link color-icon" *ngIf="showSearch">
    <em class="icon-magnifier"></em>
  </a>
  <ng-content></ng-content>
  <div class="input-group-append" *ngIf="hasAction">
    <ng-content select="[action]"></ng-content>
  </div>
  <ng-content select="[errors]"></ng-content>
</div>
