import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/core/services/base.service';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {

@Input() notifications = []


  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  goToFestivita() {
    this.router.navigate(['ferie'])
  }

}
