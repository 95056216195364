import { Routes } from "@angular/router";
import { LayoutComponent } from "../layout/layout.component";

import { LoginComponent } from "./pages/login/login.component";
import { RecoverComponent } from "./pages/recover/recover.component";
import { ActivateComponent } from "./pages/activate/activate.component";
import { LockComponent } from "./pages/lock/lock.component";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";
import { Error404Component } from "./pages/error404/error404.component";
import { Error500Component } from "./pages/error500/error500.component";
import { DipendentiComponent } from "./adminPages/dipendenti/dipendenti/dipendenti.component";
import { RequestsComponent } from "./adminPages/requests/requests/requests.component";
import { CalendarComponent } from "./adminPages/calendar/calendar/calendar.component";
import { VacationComponent } from "./adminPages/vacation/vacation/vacation.component";
import { CalendarDashboardComponent } from "./adminPages/calendar-dashboard/calendar-dashboard/calendar-dashboard.component";
import { FestivitaComponent } from "./adminPages/festivita/festivita/festivita.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { RoleSelectionComponent } from "./pages/role-selection/role-selection.component";
import { RoleGuard } from "@app/core/services/role.guard";



export const routes: Routes = [
  { path: "selectrole", component: RoleSelectionComponent},
  
  {
    path: "",
    component: LayoutComponent,
    children: [
      {path: "", redirectTo: "/login", pathMatch: "full" },
      {path: "home",loadChildren: () => import("./adminPages/home/home.module").then((m) => m.HomeModule)},
      {path: "dipendenti", loadChildren: () => import("./adminPages/dipendenti/dipendenti.module").then((m) => m.DipendentiModule),component: DipendentiComponent, canActivate : [RoleGuard] },
      {path: "dipendenti/:id",loadChildren: () => import("./adminPages/dipendenti/dipendenti.module").then((m) => m.DipendentiModule), component: DipendentiComponent, canActivate : [RoleGuard]},
      {path: "calendario/all", loadChildren: () => import("./adminPages/calendar/calendar.module").then( (m) => m.CalendarModule),component: CalendarComponent, canActivate : [RoleGuard]},
      {path: "ferie",loadChildren: () => import("./adminPages/vacation/vacation.module").then((m) => m.VacationModule),component: VacationComponent},
      {path: "richieste", loadChildren: () => import("./adminPages/requests/requests.module").then( (m) => m.RequestsModule),component: RequestsComponent},
      {path: "calendario/:id",  loadChildren: () => import( "./adminPages/calendar-dashboard/calendar-dashboard.module" ).then((m) => m.CalendarDashboardModule),component: CalendarDashboardComponent, canActivate : [RoleGuard]},
      {path: "festivita",loadChildren: () => import("./adminPages/festivita/festivita.module").then((m) => m.FestivitaModule),component: FestivitaComponent,canActivate: [RoleGuard] }
    ],
  },

  // Not lazy-loaded routes
  { path: "login", component: LoginComponent },
  { path: "login/:id", component: LoginComponent },
  { path: "recover", component: RecoverComponent },
  { path: "activate/:id", component: ActivateComponent },
  { path: "lock", component: LockComponent },
  { path: "maintenance", component: MaintenanceComponent },
  { path: "404", component: Error404Component },
  { path: "500", component: Error500Component },
  { path: "changepassword", component: ChangePasswordComponent },

  // Not found
  { path: "**", redirectTo: "404" },
];
