import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { RequestService } from "@app/core/services/request.service";
import { RoleService } from "@app/core/services/role-service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

/* export interface IS1Modal {
  modal: FerieRequestsModalComponent;
  close();
} */

/* export enum S1ModalSizes {
  SM = "modal-sm",
  MD = "",
  LG = "modal-lg",
  XL = "modal-xl",
} */

@Component({
  selector: "app-ferie-requests-modal",
  templateUrl: "./ferie-requests-modal.component.html",
  styleUrls: ["./ferie-requests-modal.component.scss"],
})
export class FerieRequestsModalComponent implements OnInit {
  @Input() request: any;
  @Input() isApprove: boolean;

  @ViewChild("approveReject") approveReject: TemplateRef<any>;
  modalRef: BsModalRef;
  @Output() changes = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor(
    private requestService: RequestService,
    private modalService: BsModalService,
    private roleService : RoleService
  ) {}

  ngOnInit(): void { 
    this.roleService.setRole(this.role)
  }

    get role() :string {
      return localStorage.getItem("role")
    }

  approveRequest(id: number) {
    this.requestService.approveRequest(id).subscribe(() => {
      this.close();
      this.changes.emit();
    });
  }

  rejectRequest(id: number) {
    this.roleService.rejectRequest(id).subscribe(() => {
      this.close();
      this.changes.emit();
    });
  }

  open() {
    this.modalRef = this.modalService.show(this.approveReject, {
      class: "modal-lg",
    });
  }

  close() {
    this.modalRef.hide();
    this.onClose.emit();
  }
}
