import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDatepickerInput } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent {
  @Output() dateRangeSelected = new EventEmitter<{ start: Date, end: Date }>();
  @Input() class: string;
  @ViewChild("startDateInput") startDateInput: ElementRef;
  @ViewChild("endDateInput") endDateInput: ElementRef;
  isInFuture!: boolean;

  startDate: Date | null = null;
  endDate: Date | null = null;

  isDateSelected: boolean = false;

  onStartDateChange(event: MatDatepickerInput<Date>) {
    this.startDate = event.value;
    this.emitDateRange();
  }

  onEndDateChange(event: MatDatepickerInput<Date>) {
    this.endDate = event.value;
    this.emitDateRange();
  }

  clearDates() {
    this.startDateInput.nativeElement.value = null;
    this.endDateInput.nativeElement.value = null;
    this.dateRangeSelected.emit({ start: null, end: null });
  }

  private emitDateRange() {
    this.isDateSelected = true;
    this.isInFuture = true;

    if (this.startDate && this.endDate) {
      this.dateRangeSelected.emit({ start: this.startDate, end: this.endDate });
    }
  }
}