import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { routes } from './routes';
import { RoleSelectionService } from '@app/core/services/roleSelection-service';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        PagesModule,
    ],
    declarations: [
  
  ],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService, private roleSelectionService : RoleSelectionService) {
      }    
    
    static forRoot(): ModuleWithProviders<RoutesModule> {
        return {
            ngModule: RoutesModule
        };
    }
  }




