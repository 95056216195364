import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@app/core/interfaces/admin.interface';
import { AppService } from '@app/core/services/base.service';
import { UtilsService } from '@app/core/services/utils.service';
import { IS1InputSelectItem } from '@app/s1';
import { randomBytes } from 'crypto';

@Component({
  selector: 'app-modal-user',
  templateUrl: './modal-user.component.html',
  styleUrls: ['./modal-user.component.scss']
})
export class ModalUserComponent implements OnInit, OnChanges {

  form: UntypedFormGroup;
  @Output() close = new EventEmitter<string>();
  @Input() modalType: string = 'show';
  @Input() user: User;
  @Output() action = new EventEmitter()

  roles: IS1InputSelectItem[] = [
    { code: "ADMIN", label: "Admin" },
    { code: "USER", label: "User" },
    { code: "REFERENCE", label: "Reference" },
  ];

  constructor(private formBuilder: FormBuilder, private appService: AppService, private utils : UtilsService) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user'] && changes['user'].currentValue) {
      this.updateForm(changes['user'].currentValue);
    }
    if (changes['modalType'] && changes['modalType'].currentValue) {
      this.updateForm(this.user);
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      username: [this.modalType !== 'create' ? this.user.username : null, Validators.required],
      firstName: [this.modalType !== 'create' ? this.user.firstName : null, Validators.required],
      lastName: [this.modalType !== 'create' ? this.user.lastName : null, Validators.required],
      email: [this.modalType !== 'create' ? this.user.email : null, [Validators.required, Validators.email]],
      status: [this.modalType !== 'create'? this.user.status : null],
      roles: [this.modalType !== 'create' ? this.user.roles : null, Validators.required],
    });

    this.updateForm(this.user);

    this.form.valueChanges.subscribe(values => {
      this.user = { ...this.user, ...values };
    });
  }

  updateForm(user: User) {
    if (this.modalType !== 'create') {
      this.form.patchValue({
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        status: user.status,
        roles: user.roles,
        password: user.password
      })

    } else {
      this.form.reset({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        status: '',
        roles: '',
        password: ''
      });
    }

    if (this.modalType === 'show') {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  closeModal() {
    this.close.emit('close');
  }

  deleteUser(userId: string) {
    this.appService.deleteUser("/api/admin/user/" + userId).subscribe();
    this.closeModal();
  }

 updateUser(){
  this.appService.editElement(`/api/admin/patch/user/${this.user.id}`, this.user).subscribe()
  this.closeModal()
  this.action.emit()
 }

  createUser() {
   
  if(this.form.valid){   

    this.user.password = this.utils.generateRandomString(10)
    this.user.status = 'CREATED'


    this.appService.newElement('/api/admin/post/user', this.user).subscribe();
    this.action.emit()
    this.closeModal();
  } else {
      console.warn("non valido il form")
  }
  }
}