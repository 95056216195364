import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { User, CalendarEvent } from "@app/core/interfaces/admin.interface";

@Component({
  selector: "app-user-card",
  templateUrl: "./user-card.component.html",
  styleUrls: ["./user-card.component.scss"],
})
export class UserCardComponent implements OnInit {
  constructor(private readonly router: Router) {}

  @Input() usersList: User[] | null = null;
  @Output() showModal = new EventEmitter<string>();

  ngOnInit(): void {}

  linkToProfile($event): void {
    new Promise<void>((resolve, reject) => {
      try {
        localStorage.setItem("userSelected", JSON.stringify($event));
        resolve();
      } catch (error) {
        reject(error);
      }
    })
      .then(() => {
        this.showModal.emit(`dipendenti`);
      })
      .catch((error) => {
        console.error("Failed to write to localStorage:", error);
      });
  }

  linkToCalendar($event): void {
    new Promise<void>((resolve, reject) => {
      try {
        localStorage.setItem("userSelected", JSON.stringify($event));
        resolve();
      } catch (error) {
        reject(error);
      }
    })
      .then(() => {
        setTimeout(() => {
          this.showModal.emit(`calendario/${$event.id}`);
        }, 10);
      })
      .catch((error) => {
        console.error("Failed to write to localStorage:", error);
      });
  }
}
